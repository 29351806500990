export const en = {
    notes: 'Notes',
    load: 'Load',
    export: 'Export',
    standardtimemanagement: 'Standard Time',
    autoupdate: 'Auto Update',
    cycletime: 'Cycle time',
    notset: 'Not set',
    hourly: 'Hourly',
    oeesku: 'OEE-SKU',
    skuassetstandardtime: 'SKU-Asset Standard Time',
    permission: 'Permissions',
    unutilised: 'Un-utilised',
    breakdowns: 'Breakdowns',
    threshold: 'Threshold',
    oeepreview: 'Oee preview',
    heatmappreview: 'Heat map preview',
    oeecolors: 'Oee colors',
    color: 'Color',
    reset: 'Reset',
    theme: 'Theme',
    defaultview: 'Default view',
    defaulttimeselection: 'Default time selection',
    zoomlevel: 'Zoom level',
    entity: 'Entity',
    preferences: 'Preferences',
    custom: 'Custom',
    enteryourmailtoreset: 'Enter your email to reset your password',
    goback: 'Go Back',
    resetpassword: 'Reset Password',
    logout: 'Logout',
    ownersemail: "Owner's email",
    optional: 'Optional',
    industry: 'Industry',
    timezone: 'Time zone',
    nocoderequesttojoin: 'No code? Request to join',
    enterreferralcodeinstead: 'Enter referral code instead',
    setup: 'setup',
    company: 'Company',
    referralcode: 'Referral Code',
    submit: 'Submit',
    summary: 'Summary',
    language: 'Language',
    login: 'Login',
    signin: 'Sign in',
    signup: 'Sign up',
    username: 'Username',
    password: 'Password',
    forgotpassword: 'Forgot password?',
    rememberme: 'Remember me',
    register: 'Register',
    registration: 'Registration',
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email',
    mobile: 'Mobile',
    country: 'Country',
    contactnumber: 'Contact Number',
    appointment: 'Appointment',
    role: 'Role',
    admin: 'admin',
    owner: 'owner',
    editor: 'editor',
    viewer: 'viewer',
    confirmpassword: 'Confirm Password',
    iacceptthe: 'I accept the',
    useragreement: 'user agreement',
    alreadyregisteredlogin: 'Already registered? Login',
    softwareasaservicetermsofuse: 'Software-as-a-Service Terms of Use',
    dashboard: 'Dashboard',
    streaming: 'Streaming',
    resolution: 'Resolution',
    seconds: 'seconds',
    second: 'second',
    minutes: 'minutes',
    minute: 'minute',
    hours: 'hours',
    hour: 'hour',
    weeks: 'weeks',
    week: 'week',
    months: 'months',
    month: 'month',
    quarters: 'quarters',
    quarter: 'quarter',
    years: 'years',
    year: 'year',
    from: 'From',
    to: 'To',
    start: 'Start',
    end: 'End',
    display: 'Display',
    oee1: 'OEE-1',
    oee2: 'OEE-2',
    timeseries: 'Time Series',
    gateway: 'Gateway',
    statusasof: 'Status as of',
    active: 'active',
    offline: 'offline',
    mergeasonemachine: 'Merge as one machine',
    drawarrowstoformprocessmap: 'Draw arrows to form process map',
    openblockeditor: 'Open block editor',
    createnewtile: 'Create New Tile',
    summarytile: 'Summary tile',
    selectablockthennameyoursummarytile:
    'Select a block, then name your summary tile',
    name: 'Name',
    blockselector: 'Block selector',
    asset: 'Asset',
    therearenolabelstaggedinthisperiod:
    'There are no labels tagged in this period',
    standardtimes: 'Standard Time (s)',
    total: 'Total',
    last: 'Last',
    oeeinput: 'OEE Input',
    chartcolor: 'Chart Color',
    issueeditor: 'Issue Editor',
    issuemangement: 'Issue Mangement',
    cumulativeoutput: 'Cumulative output',
    tag: 'Tag',
    label: 'Label',
    valueval: 'Value (Val)',
    time: 'Time',
    januaryjan: 'January (Jan)',
    februaryfeb: 'February (Feb)',
    marchmar: 'March (Mar)',
    aprilapr: 'April (Apr)',
    may: 'May',
    junejun: 'June (Jun)',
    julyjul: 'July (Jul)',
    augustaug: 'August (Aug)',
    septembersep: 'September (Sep)',
    octoberoct: 'October (Oct)',
    novembernov: 'November (Nov)',
    decemberdec: 'December (Dec)',
    day: 'day',
    days: 'days',
    mondaymonm: 'Monday (Mon) M',
    tuesdaytuet: 'Tuesday (Tue) T',
    wednesdaywedw: 'Wednesday (Wed) W',
    thursdaythut: 'Thursday (Thu) T',
    fridayfrif: 'Friday (Fri) F',
    saturdaysats: 'Saturday (Sat) S',
    sundaysuns: 'Sunday (Sun) S',
    edit: 'Edit',
    add: 'Add',
    new: 'new',
    create: 'Create',
    search: 'Search',
    close: 'Close',
    cancel: 'Cancel',
    save: 'Save',
    saved: 'Saved',
    clear: 'Clear',
    enter: 'Enter',
    navigator: 'Navigator',
    mandatoryfields: 'Mandatory Fields',
    select: 'Select',
    merge: 'Merge',
    delete: 'Delete',
    done: 'Done',
    filter: 'Filter',
    table: 'Table',
    columns: 'columns',
    rows: 'rows',
    cell: 'cell',
    page: 'Page',
    previous: 'Previous',
    next: 'Next',
    serialnumber: 'Serial Number',
    manuallyinputserialnumber: 'Manually input Serial Number',
    updateasset: 'Update Asset',
    machine: 'Machine',
    brand: 'Brand',
    model: 'Model',
    nodes: 'Nodes',
    fusions: 'Fusions',
    chartorder: 'Chart Order',
    primary: 'Primary',
    adddevicechannel: 'Add Device - Channel',
    pushratesec: 'Push Rate (sec)',
    voltagev: 'Voltage (V)',
    sensor: 'Sensor',
    mode: 'Mode',
    digitalcount: 'Digital Count',
    digitalstate: 'Digital State',
    analogvoltage: 'Analog Voltage',
    charttitle: 'Chart Title',
    unitmeasure: 'Unit Measure',
    attribute: 'Attribute',
    autocycletime: 'Auto Cycle time',
    unittransform: 'Unit Transform',
    leaveblankifnotapplicable: 'Leave blank if not applicable',
    mandatoryfieldsleaveblankifchannelnotinuse:
    'Mandatory fields (leave blank if channel not in use)',
    fusedchart: 'Fused Chart',
    fusionname: 'Fusion Name',
    charttype: 'Chart Type',
    column: 'Column',
    line: 'Line',
    state: 'State',
    expression: 'Expression',
    combination: 'Combination',
    currentcharts: 'Current Charts',
    expressionconstructor: 'Expression Constructor',
    validoperators: 'Valid operators',
    leaveblanktokeepexistingexpression: 'Leave blank to keep existing expression',
    oee: 'OEE',
    overallequipmenteffectiveness: 'Overall Equipment Effectiveness',
    legend: 'Legend',
    loading: 'Loading',
    availability: 'Availability',
    performance: 'Performance',
    quality: 'Quality',
    unscheduled: 'Unscheduled',
    planneddowntime: 'Planned Downtime',
    changeoversetup: 'Changeover/ Setup',
    breakdown: 'Breakdown',
    unutilized: 'Un-utilized',
    speedloss: 'Speed Loss',
    minorstops: 'Minor Stops',
    rework: 'Rework',
    rejectscrap: 'Reject / Scrap',
    nodata: 'No data',
    blockeditor: 'Block Editor',
    sort: 'Sort',
    logicblocks: 'Logic Blocks',
    sequence: 'Sequence',
    and: 'And',
    or: 'Or',
    utilizationheatmap: 'Utilization Heatmap',
    startdate: 'Start Date',
    enddate: 'End Date',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    yearly: 'Yearly',
    trendanalysis: 'Trend Analysis',
    metricselection: 'Metric Selection',
    metric: 'Metric',
    data: 'Data',
    target: 'Target',
    periodselection: 'Period Selection',
    span: 'Span',
    every: 'Every',
    repeat: 'Repeat',
    repeats: 'Repeats',
    addsingle: 'Add Single',
    paretoanalysis: 'Pareto Analysis',
    effective: 'Effective',
    loadingloss: 'Loading Loss',
    availabilityloss: 'Availability Loss',
    performanceloss: 'Performance Loss',
    qualityloss: 'Quality Loss',
    notagsinthiscategory: 'No tags in this category',
    overall: 'Overall',
    tagged: 'Tagged',
    occurrence: 'Occurrence',
    skumanagement: 'SKU Management',
    category: 'Category',
    skuname: 'SKU Name',
    skucode: 'SKU Code',
    unitofmeasurementuom: 'Unit of Measurement (UOM)',
    description: 'Description',
    csvupload: 'CSV Upload',
    template: 'Template',
    notifications: 'Notifications',
    newnotification: 'New Notification',
    monitoring: 'Monitoring',
    topic: 'Topic',
    frequency: 'Frequency',
    recipients: 'Recipients',
    notifyby: 'Notify by',
    sms: 'SMS',
    alert: 'Alert',
    report: 'Report',
    greaterthan: 'Greater than',
    lessthan: 'Less than',
    greaterthanorequal: 'Greater than or equal',
    lessthanorequal: 'Less than or equal',
    equal: 'Equal',
    range: 'Range',
    continuousduration: 'Continuous Duration',
    usermanagement: 'User Management',
    user: 'User',
    users: 'Users',
    invitenew: 'Invite New',
    status: 'Status',
    resend: 'Resend',
    revoke: 'Revoke',
    invite: 'Invite',
    invitees: 'Invitees',
    addinviteesbypressingenterorclickingonthesign:
    "Add invitees by pressing Enter or clicking on the '+' sign",
    schedules: 'Schedules',
    shift: 'Shift',
    profile: 'Profile',
    changepassword: 'Change Password',
    deleteaccount: 'Delete Account',
    userguide: 'User Guide',
    overviewandbasiccontrol: 'Overview and Basic Control',
    rangeandresolution: 'Range and Resolution',
    editingoptions: 'Editing Options',
    mergetiles: 'Merge Tiles',
    detailedassetview: 'Detailed Asset View',
    assetoverview: 'Asset Overview',
    summarystatistics: 'Summary Statistics',
    dataanalysis: 'Data Analysis',
    oeeanoverview: 'OEE - An Overview',
    oeetoggle: 'OEE Toggle',
    addingamachine: 'Adding a Machine',
    nodeconfiguration: 'Node Configuration',
    deleteupdateassets: 'Delete/ Update Assets',
    oeesummarytile: 'OEE Summary Tile',
    oeetags: 'OEE Tags',
    issuemanagement: 'Issue Management',
    labelsandfiltering: 'Labels and Filtering',
    useraccess: 'User Access',
    group: 'Group',
    groups: 'Groups',
    joincompany: 'Join Company',
    notification: 'Notification',
    createnotification: 'Create Notification',
    node: 'Node',
    downtime: 'Downtime',
    downtimes: 'Downtimes',
    repeatstartdate: 'Repeat Start Date',
    repeatenddate: 'Repeat End Date',
    optional: 'Optional',
    createplan: 'Create Plan',
    today: 'Today',
    starttime: 'Start Time',
    endtime: 'End Time',
    chart: 'Chart',
    reject: 'Reject',
    accept: 'Accept',
    filterbydaterange: 'Filter by date range',
    filterbymostrecent: 'Filter by most recent',
    result: 'Result',
    type: 'Type',
    duration: 'Duration',
    by: 'By',
    title: 'Title',
    assigned: 'Assigned',
    action: 'Action',
    track: 'Track',
    issue: 'Issue',
    priority: 'Priority',
    requestor: 'Requestor',
    opened: 'Opened',
    assignee: 'Assignee',
    root: 'Root',
    cause: 'Cause',
    identified: 'Identified',
    defined: 'Defined',
    date: 'Date',
    resolve: 'Resolve',
    resolved: 'Resolved',
    targetresolvedate: 'Target Resolve Date',
    procurement: 'Procurement',
    choose: 'Choose',
    analyses: 'Analyses',
    source: 'Source',
    selection: 'Selection',
    historical: 'Historical',
    mostrecent: 'Most Recent',
    regression: 'Regression',
    trendline: 'Trendline',
    upperbound: 'Upper-bound',
    lowerbound: 'Lower-bound',
    preview: 'Preview',
    period: 'Period',
    series: 'Series',
    axis: 'axis',
    youhaveno: 'You have no',
    code: 'Code',
    all: 'All',
    upload: 'Upload',
    regressionanalysis: 'Regression Analysis',
    issuetracker: 'Issue Tracker',
    labelsearch: 'Label Search',
    gatewaysetup: 'Gateway Setup',
    setupgateway: 'Setup Gateway',
    radiomesh: 'Radio Mesh',
    targetsetting: 'Target Setting',
    updatetrackedissue: 'UPDATE TRACKED ISSUE',
    shownewtrackedissue: 'SHOW NEW TRACKED ISSUE',
    low: 'Low',
    mid: 'Mid',
    high: 'High',
    linear: 'Linear',
    exponential: 'Exponential',
    logarithmic: 'Logarithmic',
    quadratic: 'Quadratic',
    polynomial: 'Polynomial',
    general: 'General',
    datataggingenrichment: 'Data Tagging / Enrichment',
    hardware: 'Hardware',
    nodemanagement: 'Node Management',
    createeditnotification: 'Create/Edit Notification',
    conditions: 'Conditions',
    addsku: 'Add SKU',
    editsku: 'Edit SKU',
    setupagateway: 'Setup a gateway to stream data from your devices.',
    deviceserialnumber: 'Device Serial Number',
    repeatsweekly: 'Repeats weekly',
    repeatsdaily: 'Repeats daily',
    repeatsmonthly: 'Repeats monthly',
    repeatsannually: 'Repeats annually',
    repeatsevery: 'Repeats every',
    hourly: 'Hourly',
    biweekly: 'Bi-weekly',
    hardware: 'Hardware',
    assetmanagement: 'Asset Management',
    currentshift: 'Current Shift',
    sum: 'Sum',
    modifiedby: 'Modified By',
    defaultoeeselection: 'Default OEE Selection',
    configureoperatorconsole: 'Configure Operator Console',
    home: 'Home',
    operationshub: 'Operations Hub',
    productionachievement: 'Production Achievement',
    blockview: 'Block View',
    assetview: 'Asset View',
    planninghub: 'Planning Hub',
    ciimpact: 'CI & Impact',
    impactsuite: 'Impact Suite',
    advancedanalytics: 'Advanced Analytics',
    teamandorganization: 'Team and Organization',
    settingsandpreferences: 'Settings and Preferences',
    calendartime: 'Calendar Time',
    scheduledproductiontime: 'Scheduled Production Time',
    grossoperatingtime: 'Gross Operating Time',
    netoperatingtime: 'Net Operating Time',
    editasset: 'Edit Asset',
    assetid: 'Asset ID',
    iotnodes: 'IoT Nodes',
    version: 'Version',
    inputtype: 'Input Type',
    noisefilter: 'Noise Filter',
    minmaxfilter: 'Min/Max Filter',
    scheduling: 'Scheduling',
    block: 'Block',
    goalsetting: 'Goal Setting',
    // productionoperator: 'Production Operator',
    // productionsupervisor: 'Production Supervisor',
    // productionmanager: 'Production Manager',
    // cileanengineer: 'CI/Lean Engineer',
    // cileanmanager: 'CI/Lean Manager',
    // maintenancetechnician: 'Maintenance Technician',
    // maintenanceengineer: 'Maintenance Engineer',
    // maintenancemanager: 'Maintenance Manager',
    // qualityinspector: 'Quality Inspector',
    // qualityengineer: 'Quality Engineer',
    // qualitymanager: 'Quality Manager',
    // itengineer: 'IT Engineer',
    // itmanager: 'IT Manager',
    // plantmanagerdirector: 'Plant Manager/Director',
    // executive: 'Executive',
    // others: 'Others',
    vicepresidentpresident: "Vice President/President",
    executive: "Executive",
    plantmanagerdirector: "Plant Manager/Director",
    productionmanager: "Production Manager",
    productionsupervisor: "Production Supervisor",
    productionoperator: "Production Operator",
    engineeringmanager: "Engineering Manager",
    processengineer: "Process Engineer",
    cileanmanager: "CI/Lean Manager",
    cileanengineer: "CI/Lean Engineer",
    planningmanager: "Planning Manager",
    plannerscheduler: "Planner/Scheduler",
    maintenancemanager: "Maintenance Manager",
    maintenanceengineer: "Maintenance Engineer",
    maintenancetechnician: "Maintenance Technician",
    qualityinspector: "Quality Inspector",
    qualityengineer: "Quality Engineer",
    qualitymanager: "Quality Manager",
    itengineer: "IT Engineer",
    itmanager: "IT Manager",
    finance: "Finance",
    financemanager: "Finance Manager",
    others: "Others",
    timebasedreport: 'Time Based Report',
    skureport: 'SKU Report',
    issuereport: 'Issue Report',
    assetevents: 'Event Labelling',
    assetoperator: 'Operator Console'
};

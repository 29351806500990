

// LIBRARIES
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Menu, Modal } from 'antd';

// CONTEXT
import { AssetDomainContext } from '.';
import CONSTANTS from "../../../Constants"

// COMPONENTS
import AssetConfiguration from '../AssetConfiguration';
import AukButton from '../../../components/AukButton';
import BlockTitle from '../shared/BlockTitle';
import AukTooltip from '../../../components/AukTooltip';
import ModalPanel from '../../../components/ModalPanel';
import WithBlockUsers from '../../Blocks/WithBlockUsers';
import { PanelBody, PanelFooter, PanelHeader } from '../../../components/Panel';
import { Permission, RolePermission } from '../../../components/Permission';

// SELECTORS
import { oeeCategoriesSelector } from '../../../../store/old/UI/OEE/OEE.selector';

// HELPERS
import { getAssetCSV, getAssetLabelsCSV } from '../Assets.csv_exporter';
import { multiSaveCsv } from '../../../utils/dataExports';
import BlockCSVExportModal from '../../Blocks/BlockCSVExportModal';
import { CalendarOutlined } from '@ant-design/icons';
import useTranslate from '../../../../hooks/useTranslate';
import { FlagOutlined, TabletAndroidOutlined } from '@mui/icons-material';

const EXPORT_KEY = 'export';
const CLASSIC_EXPORT_KEY = 'classic_export';

const AssetHeader = () => {
    const { asset } = useContext(AssetDomainContext);
    const oeeCategories = useSelector(oeeCategoriesSelector);
    const { translate } = useTranslate()

    const navigate = useNavigate();

    if (!asset) return null;

    const { asset_name, block_id } = asset;

    const [nodeConfigVisible, setNodeConfigVisibility] = useState(false);
    const [exportVisible, setExportVisibility] = useState(false);

    const handleClickPareto = () => {
        const { PARETO } = CONSTANTS.URLS;
        const { search } = window.location;

        navigate(`${PARETO}/${block_id}${search}`);
    };

    const handleClickOperatorConsole = () => {
        const { ASSET } = CONSTANTS.URLS;
        const { search } = window.location;

        navigate(`${ASSET}/${block_id}/operator${search}`);
    };

    const handleClickPlanner = () => {
        const { ASSET } = CONSTANTS.URLS;

        navigate(`${ASSET}/${block_id}/planner`);
    };

    const handleClickEvents = () => {
        const { ASSET } = CONSTANTS.URLS;

        navigate(`${ASSET}/${block_id}/events`);
    };

    const downloadCSV = () => {
        const mainFile = {
            data: getAssetCSV(asset, oeeCategories),
            fileName: `${asset_name}_data.csv`,
        };
        const labelFile = {
            data: getAssetLabelsCSV(asset),
            fileName: `${asset_name}_label.csv`,
        };

        multiSaveCsv([mainFile, labelFile]);
    };

    const handleClickMenu = ({ key }) => {
        switch (key) {
        case EXPORT_KEY:
            setExportVisibility(true);
            return;
        case CLASSIC_EXPORT_KEY:
            downloadCSV();
            return;
        default:
            return;
        }
    };

    return (
        <WithBlockUsers block={asset.block}>
            <>
                {/* {nodeConfigVisible && (
                    <ModalPanel>
                        <PanelHeader>{translate('Edit Asset')}</PanelHeader>
                        <PanelBody>
                            <AssetConfiguration asset={asset} showDeleteAsset={false} />
                        </PanelBody>
                        <PanelFooter className="p-3 w-100 d-flex">
                            <AukButton.Cancel
                                onClick={() => setNodeConfigVisibility(false)}
                            />
                        </PanelFooter>
                    </ModalPanel>
                )} */}
                <Modal
                    className='auk-modal'
                    title={translate('Edit Asset')}
                    open={nodeConfigVisible}
                    onCancel={() => setNodeConfigVisibility(false)}
                    footer={
                        <div className="d-flex">
                            <AukButton.Cancel
                                onClick={() => setNodeConfigVisibility(false)}
                            />
                        </div>
                    }
                >
                    <AssetConfiguration asset={asset} showDeleteAsset={false} />
                </Modal>
                <BlockCSVExportModal
                    visible={exportVisible}
                    onCancel={() => setExportVisibility(false)}
                    block={asset.block}
                />
                <BlockTitle id={asset.block_id}>
                    <div className="d-flex title-buttons">
                        <Permission resource="pareto_analysis" forResource canDo="view">
                            <AukTooltip.Help title={translate('paretoanalysis')}>
                                <AukButton.Outlined
                                    className="auk-button--round mr-2"
                                    style={{ transform: 'rotate(270deg) scaleY(-1)' }}
                                    onClick={handleClickPareto}
                                >
                                    <i className="fas fa-signal i-i" />
                                </AukButton.Outlined>
                            </AukTooltip.Help>
                        </Permission>
                        <Permission resource="assets" forResource canDo="view">
                            <AukTooltip.Help title={translate('nodeconfiguration')}>
                                <AukButton.Outlined
                                    className="auk-button--round mr-2"
                                    onClick={() => setNodeConfigVisibility(true)}
                                >
                                    <i className="fas fa-bars" />
                                </AukButton.Outlined>
                            </AukTooltip.Help>
                        </Permission>
                        <Permission resource="assets" forResource canDo="view">
                            <RolePermission accessLevel="editor">
                                <AukTooltip.Help title={translate('export')}>
                                    <AukButton.Dropdown
                                        className="auk-button--round mr-2"
                                        icon={<i className="fas fa-download" />}
                                        placement="bottomLeft"
                                        trigger={['click']}
                                        overlay={
                                            <Menu onClick={handleClickMenu}>
                                                <Menu.Item key={EXPORT_KEY}>Export</Menu.Item>
                                                <Menu.Item key={CLASSIC_EXPORT_KEY}>
                          Classic Export
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    />
                                </AukTooltip.Help>
                            </RolePermission>
                        </Permission>
                        <AukTooltip.Help title={'Operator Console'}>
                            <AukButton.Outlined
                                className="auk-button--round mr-2"
                                onClick={handleClickOperatorConsole}
                            >
                                <TabletAndroidOutlined style={{fontSize: 14}}/>
                            </AukButton.Outlined>
                        </AukTooltip.Help>
                        <AukTooltip.Help title={'Planner'}>
                            <AukButton.Outlined
                                className="auk-button--round mr-2"
                                onClick={handleClickPlanner}
                            >
                                <CalendarOutlined />
                            </AukButton.Outlined>
                        </AukTooltip.Help>
                        <AukTooltip.Help title={'Events'}>
                            <AukButton.Outlined
                                className="auk-button--round mr-2"
                                onClick={handleClickEvents}
                            >
                                <FlagOutlined style={{fontSize: 16}}/>
                            </AukButton.Outlined>
                        </AukTooltip.Help>
                    </div>
                </BlockTitle>
            </>
        </WithBlockUsers>
    );
};

export default AssetHeader;

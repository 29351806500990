import moment from "moment";

export const LabelEvent = ({ label_id, from, to, oee, issue_id, label }) => {
    const duration = moment.duration(moment(to).diff((moment(from)))).as('minutes');
    return {
        id: generateLabelEventId(label_id, from, to),
        label_id,
        from,
        to,
        oee,
        issue_id,
        duration,
        label
    }
}

const generateLabelEventId = (label_id, from, to) => `${label_id || ''}|${from}|${to}`;
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import './BackAnchor.scss';

import withRouter from '../../../Wrappers/HOCs/withRouter'
import CONSTANTS from '../../../Constants';

const BackAnchor = (props) => {
    const assetUrl = useMemo(() => {
        const blockId = props.router.params.blockId;
        return `${CONSTANTS.URLS.ASSET}/${blockId}`
    }, []);

    return (
        <div className="asset-back-anchor">
            <Link to={assetUrl}>Back to asset</Link>
        </div>
    )
}

export default withRouter(BackAnchor)
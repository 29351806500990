import { SaveOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import './InputWithSubmit.scss';

const InputWithSubmit = (props) => {
    const [form] = Form.useForm()

    const submit = useCallback(
        () => {
            const { entry } =  form.getFieldsValue(true);
            props.submit(entry)
        },
        [form]
    );

    return (
        <Form form={form} onFinish={submit}>
            <Form.Item name="entry" initialValue={props.value} rules={props.rules}>
                <Input
                    {...props.inputProps}
                    disabled={props.disabled}
                    className={classNames({
                        'auk-input-with-submit': true,
                        'auk-input-with-submit--disabled': props.disabled,
                        [props.className]: true,
                    })}
                    addonAfter={
                        <button className="auk-input__submit" type="submit">
                            <SaveOutlined />
                        </button>
                    }
                />
            </Form.Item>
        </Form>
    );
};

export default InputWithSubmit;
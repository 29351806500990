import { Collapse, Form, Modal, Select, Space, TimePicker, TreeSelect } from 'antd';
import React, { useMemo, useState } from 'react';

import './ReportFilter.scss'
import { defaultFilterOption } from '../../../utils/helpers';
import { getBlocksTreeData } from '../../../utils/blocks';
import { entityBlock } from '../../../../store/old/Blocks/Blocks.selector';
import { useDispatch, useSelector } from 'react-redux';
import AukButton from '../../../components/AukButton';
import { CaretRightOutlined, EllipsisOutlined, FilterOutlined } from '@ant-design/icons';
import { setOeeReportFilter } from '../../../../store/old/UI/Report/Report.action'
import ReportRangePicker from './ReportRangePicker';

const { RangePicker: TimeRangePicker } = TimePicker;

const PERIOD_OPTIONS = [
    { value: 'days', label: 'Daily' },
    // { value: 'weeks', label: 'Weekly' },
    // { value: 'months', label: 'Monthly' }
];

const VALIDATE = {
    DATE_RANGE: [
        {
            required: true,
            message: 'Date range is required.',
        },
        (_form) => ({
            validator: (_, value) => {
                const [start, end] = value;
                
                if (!start || !end) {
                    return Promise.reject(new Error('Date range is required'));
                }
                return Promise.resolve();
            },
        })
    ],
    PERIOD: [
        {
            required: true,
            message: 'Period is required.',
        }
    ],
    BLOCK: [
        {
            required: true,
            message: 'Asset is required.',
        }
    ],
}

const TimeSeriesFilter = (props) => {
    const dispatch = useDispatch();
    const filterState = useSelector(appState => appState.ui.report.oeeFilter);
    const rootBlock = useSelector(entityBlock);
    const [form] = Form.useForm();

    const [showModal, setShowModal] = useState(false);

    const blocksTreeData = useMemo(() => {
        return [getBlocksTreeData(rootBlock, { disabledAccessor: (n) => !n.asset || (n.asset && !n.asset._primaryChart) })]
    }, [rootBlock]);


    return (
        <Space>
            <Form
                name="time-series-filter"
                id="timeSeriesFilter"
                form={form}
                className="report-filter"
                onFinish={() => {
                    const formState = form.getFieldsValue(true);
                    dispatch(setOeeReportFilter(formState))
                }}
            >
                <Form.Item
                    colon={false}
                    label={'Date Range'}
                    name="dateRange"
                    initialValue={filterState.dateRange}
                    rules={VALIDATE.DATE_RANGE}
                >
                    <ReportRangePicker />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label={'Period'}
                    name="period"
                    initialValue={filterState.period}
                    rules={VALIDATE.PERIOD}
                >
                    <Select style={{width: 120}} options={PERIOD_OPTIONS}/>
                </Form.Item>
                <Form.Item
                    colon={false}
                    label={'Assets'}
                    name="blockIds"
                    initialValue={filterState.blockIds}
                    rules={VALIDATE.BLOCK}
                >
                    <TreeSelect
                        maxTagCount={1}
                        maxTagPlaceholder={<EllipsisOutlined />}
                        multiple
                        allowClear
                        style={{width: 280}}
                        treeDefaultExpandAll
                        showSearch
                        filterTreeNode={defaultFilterOption}
                        treeData={blocksTreeData}
                    />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label=" "
                >
                    <AukButton.Outlined onClick={() => { setShowModal(true) }}>
                        <FilterOutlined/> Filters
                    </AukButton.Outlined>
                </Form.Item>
                <Form.Item
                    colon={false}
                    label=" "
                >
                    <AukButton.Blue htmlType="submit" ghost>
                        <CaretRightOutlined/> Apply
                    </AukButton.Blue>
                </Form.Item>
                <Modal
                    className="auk-modal report-time-series-modal"
                    title="Filters"
                    open={showModal}
                    onCancel={() => { setShowModal(false) }}
                    onOk={() => { 
                        form.submit(); 
                        setShowModal(false);
                    }}
                >
                    <Collapse ghost bordered={false} defaultActiveKey={["1"]}>
                        <Collapse.Panel header="Time" key="1">
                            <Form.Item
                                colon={false}
                                label=" "
                                name="timeRange"
                                initialValue={filterState.timeRange}
                            >   
                                <TimeRangePicker
                                    allowClear={true}
                                    format="HH:mm"
                                    order={false}
                                />
                            </Form.Item>    
                            <div className="mt-2" style={{fontSize: 11}}>* <i>Leave blank to use default day-start time</i></div>
                        </Collapse.Panel>
                    </Collapse>
                </Modal>
            </Form>
        </Space>
    )
}

export default TimeSeriesFilter
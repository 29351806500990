import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import "./UserPreferencesTab.scss"
import React, { useEffect, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { entityUserPreferencesDestroyAction, entityUserPreferencesGetAction, entityUserPreferencesStoreAction } from "../../../store/actions/preferences.action"
import useCurrentEntity from "../../../hooks/useCurrentEntity"
import VTable, { CustomFilterDropdown, CustomFilterIcon } from "../../../legacy/components/VTable"
import { isEmpty, keys, values } from "lodash"
import { type UserPreferenceModel } from "../../../api/models/preference.model"
import ProcessTreeSelector from "../../../components/ProcessSelector/ProcessTreeSelector"
import { getDefaultResolution } from "../../../legacy/utils/controls"
import moment, { type unitOfTime } from "moment"
import { Controller, useForm } from "react-hook-form"
import { flash } from "../../../legacy/components/Flash"
import { regexMatch } from "../../../legacy/utils/helpers"
import ConfirmPopoverButton from "../../../components/Popover/ConfirmPopoverButton"

const UserPreferencesTab = () => {
    const dispatch = useAppDispatch()
    const { entityId } = useCurrentEntity()
    const users = useAppSelector(appState => appState.user.list)
    const userPreferences = useAppSelector(appState => appState.preferences.preferences)

    const { control, watch, getValues, register: registerField, setValue, formState, trigger, reset } = useForm<Form>({ mode: "onChange" })

    useEffect(() => { void dispatch(entityUserPreferencesGetAction({ entityId })) }, [entityId])
    useEffect(() => { void trigger() }, [])

    const userList = useMemo(() => values(users)
        .filter((v) => v.entity_id === entityId)
        .sort((a, b) => a.nameFull.localeCompare(b.nameFull))
    , [users])
    const userPreferenceMap = useMemo(() => values(userPreferences).reduce((acc, p) => ({ ...acc, [p.userId]: p }), {}), [userPreferences])

    useEffect(() => {
        const updatedFormValues = userList.reduce((acc, user) => {
            const preference = userPreferenceMap[user.user_id]
            if (preference) {
                return {
                    ...acc,
                    [user.user_id]: findPreferenceFields(preference)
                }
            }

            return {
                ...acc,
                [user.user_id]: defaultPreferenceFields(user)
            }
        }, {})

        reset(updatedFormValues)
    }, [userList, userPreferenceMap])

    const dataSource: UserPreferenceRow[] = useMemo(() => userList.map(user => {
        return {
            userId: user.user_id,
            username: `${user.name_first} ${user.name_last}`,
            email: user.email,
        }
    }), [userList])

    const columns = [
        {
            title: "User Name",
            render: (_text: any, record: UserPreferenceRow) => {
                return `${record.username} (${record.email})`
            },
            sorter: (a: UserPreferenceRow, b: UserPreferenceRow) => a.username.localeCompare(b.username),
            width: 200,
            filterSearch: true,
            onFilter: (value: string, record: UserPreferenceRow) =>
                regexMatch(`${record.username} ${record.email}`, value, { escape: true }),
            filterDropdown: CustomFilterDropdown,
            filterIcon: (filtered: boolean) => <CustomFilterIcon active={filtered} />,
        },
        {
            title: "Range",
            render: (_text: any, record: UserPreferenceRow, index: number) => {
                const register = registerField(`${record.userId}.range`)
                return <Select
                    fullWidth
                    id={`prefRangeSelect${index}`}
                    size="small"
                    {...register}
                    onChange={(e) => {
                        void register.onChange(e)
                        const resolution = findDefaultResolutionForSpan(e.target.value)
                        setValue(`${record.userId}.resolution`, resolution)
                    }}
                    value={getValues(`${record.userId}`).range}
                >
                    {
                        spanOptions.map((item, i) => <MenuItem key={i} value={item.value}>{item.label}</MenuItem>)
                    }
                </Select>
            },
            width: 120,
        },
        {
            title: "Resolution",
            render: (_text: any, record: UserPreferenceRow) => {
                watch(`${record.userId}.range`)
                return <TextField
                    fullWidth
                    disabled
                    size="small"
                    id={"prefResolutionInput{index}"}
                    value={getValues(`${record.userId}.resolution`)}
                />
            },
            width: 120,
        },
        {
            title: "Homepage",
            render: (_text: any, record: UserPreferenceRow, index: number) => {
                const register = registerField(`${record.userId}.homepage`)
                return <Select
                    fullWidth
                    id={`prefHomepageSelect${index}`}
                    size="small"
                    {...register}
                    onChange={(e) => {
                        void register.onChange(e)
                        const requiresBlockId = e.target.value?.match(":block_id")
                        if (requiresBlockId) {
                            setValue(`${record.userId}.blockId`, null)
                            void trigger(`${record.userId}`)
                        }
                    }}
                    value={getValues(`${record.userId}.homepage`)}

                >
                    {
                        homepageOptions.map((item, i) => <MenuItem key={i} value={item.value}>{item.label}</MenuItem>)
                    }
                </Select>
            },
            width: 180,
        },
        {
            title: "Block or asset page (Optional)",
            render: (_text: any, record: UserPreferenceRow) => {
                const homepageValue = watch(`${record.userId}.homepage`)
                const show = homepageValue?.match(":block_id")
                const showAsset = homepageValue?.match("asset")
                const showAll = homepageValue?.match("pareto")

                return <Controller
                    key={homepageValue}
                    rules={{ validate: (val) => show ? val !== null : true, deps: [`${record.userId}.homepage`] }}
                    defaultValue={null}
                    name={`${record.userId}.blockId`}
                    control={control}
                    render={({ field, fieldState }) => {
                        return show
                            ? <ProcessTreeSelector
                                nodeProps={{
                                    disabled: (node) => {
                                        if (showAll) return false
                                        if (showAsset) return !node.assetId
                                        return !!node.assetId
                                    }
                                }}
                                value={field.value}
                                onChange={field.onChange}
                                size="small"
                                error={fieldState.invalid}
                                helperText="Required"
                                disableClearable
                                onBlur={field.onBlur}
                            />
                            : <TextField
                                disabled
                                hidden
                            />
                    }}
                />
            },
            width: 180,
        },
        {
            title: "Clear Preference",
            render: (_text: any, record: UserPreferenceRow) => {
                const preferenceId = getValues(`${record.userId}.preferenceId`)
                return <div>
                    <TextField
                        disabled
                        hidden
                        type="number"
                        {...registerField(`${record.userId}.preferenceId`)}
                    />
                    <TextField
                        disabled
                        hidden
                        type="number"
                        {...registerField(`${record.userId}.userId`)}
                    />
                    <ConfirmPopoverButton
                        id={`deleteUserPreference-${record.userId}`}
                        onConfirm={() => { preferenceId && handleDelete(preferenceId) }}
                        disabled={!preferenceId}
                    >
                        <Typography sx={{ p: 2 }}>Delete preference for {record.username}?</Typography>
                    </ConfirmPopoverButton>
                </div>
            },
            width: 120,
        }
    ]

    const handleSubmit = () => {
        if (!isEmpty(formState.errors)) {
            flash({ message: "Please check required fields", status: "warning" })
        }

        const touchedFields = keys(formState.touchedFields)
        const records = getValues(touchedFields).map(compileRecordForSubmission)

        void dispatch(entityUserPreferencesStoreAction({ entityId, payload: records }))
    }

    const handleDelete = (preferenceId: number) => {
        void dispatch(entityUserPreferencesDestroyAction({ entityId, preferenceId }))
    }

    return <Box className="w-100 h-100" display={"flex"} flexDirection={"column"} >
        <Box padding={"16px 0px"} display={"flex"} justifyContent={"flex-end"}>
            <Button variant="contained" onClick={handleSubmit}>Save Preferences</Button>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
            <VTable
                rowHeight={72}
                columns={columns}
                dataSource={dataSource}
                rowKey={(row: any) => row.userId}
            />
        </Box>
    </Box>
}

export default UserPreferencesTab

interface UserPreferenceRow {
    userId: number
    username: string
    email: string
    preference?: UserPreferenceModel
}

interface FormRow {
    preferenceId?: number
    userId: number
    range: string | undefined
    resolution: string | undefined
    homepage: string | undefined
    blockId: number | null
}

type Form = Record<string, FormRow>

const spanOptions = [
    { label: "15 minutes", value: "15 minutes" },
    { label: "1 hour", value: "1 hours" },
    { label: "4 hours", value: "4 hours" },
    { label: "8 hours", value: "8 hours" },
    { label: "12 hours", value: "12 hours" },
    { label: "1 day", value: "1 days" },
    { label: "1 week", value: "1 weeks" },
    { label: "1 month", value: "1 months" },
    { label: "3 months", value: "3 months" },
    { label: "1 year", value: "1 years" },
]

export const findDefaultResolutionForSpan = (span: string) => {
    const [x, unit] = span.split(" ")
    const resolution = getDefaultResolution(moment.duration(x, unit as unitOfTime.Base).as("seconds"))
    return `${resolution.res_x} ${resolution.res_period}`
}

enum HomepageUrlEnum {
    DASHBOARD = "/dashboard",
    BLOCK = "/block/:block_id",
    ASSET = "/asset/:block_id",
    PARETO = "/pareto_analysis/:block_id",
    OPERATOR = "/asset/:block_id/operator",
    EVENTS = "/asset/:block_id/events",
    UTILIZATION = "/utilization",
    TREND = "/trend_analysis",
    REGRESSION = "/regression_analysis",
    ISSUE_TRACKER = "/issue_tracker",
    NOTIFICATIONS = "/notifications",
    SCHEDULING = "/scheduling",
    HARDWARE_MANAGEMENT = "/hardware_management",
    ASSET_MANAGEMENT = "/asset_management",
    ISSUE_MANAGEMENT = "/issue_management",
    SKU_MANAGEMENT = "/sku_management",
    SEARCH = "/search",
    TARGET_SETTING = "/target_setting",
    PREFERENCES = "/preferences",
    STANDARD_TIMES = "/standard_time",
    PERMISSIONS_MANAGEMENT = "/permissions",
    USER = "/user",
    USER_MANAGEMENT = "/user_management",
    PRODUCTION_ACHIEVEMENT = "/production_achievement",
    IMPACT_SUITE = "/impact",
    GOAL_SETTING = "/goals",
}

const homepageOptions = [
    { label: "Dashboard", value: HomepageUrlEnum.DASHBOARD },
    { label: "Production Achievement", value: `${HomepageUrlEnum.DASHBOARD}${HomepageUrlEnum.PRODUCTION_ACHIEVEMENT}` },
    { label: "Block View", value: HomepageUrlEnum.BLOCK },
    { label: "Asset View", value: HomepageUrlEnum.ASSET },
    { label: "Operator Console", value: HomepageUrlEnum.OPERATOR },
    { label: "Events Labelling", value: HomepageUrlEnum.EVENTS },
    { label: "Shifts / Schedules", value: HomepageUrlEnum.SCHEDULING },
    { label: "SKU Management", value: HomepageUrlEnum.SKU_MANAGEMENT },
    { label: "Standard Time", value: HomepageUrlEnum.STANDARD_TIMES },
    { label: "Target Setting", value: HomepageUrlEnum.TARGET_SETTING },
    { label: "Issue Management", value: HomepageUrlEnum.ISSUE_MANAGEMENT },
    { label: "Issue Tracker", value: HomepageUrlEnum.ISSUE_TRACKER },
    { label: "Label Search", value: HomepageUrlEnum.SEARCH },
    { label: "Notifications", value: HomepageUrlEnum.NOTIFICATIONS },
    { label: "Impact Suite", value: HomepageUrlEnum.IMPACT_SUITE },
    { label: "Goal Setting", value: HomepageUrlEnum.GOAL_SETTING },
    { label: "Pareto Analysis", value: HomepageUrlEnum.PARETO },
    { label: "Trend Analysis", value: HomepageUrlEnum.TREND },
    { label: "Utilization Heatmap", value: HomepageUrlEnum.UTILIZATION },
    { label: "Regression Analysis", value: HomepageUrlEnum.REGRESSION },
    { label: "User Management", value: HomepageUrlEnum.USER_MANAGEMENT },
    { label: "Permissions", value: HomepageUrlEnum.PERMISSIONS_MANAGEMENT },
    { label: "Hardware Management", value: HomepageUrlEnum.HARDWARE_MANAGEMENT },
    { label: "Asset Management", value: HomepageUrlEnum.ASSET_MANAGEMENT },
    { label: "Entity Settings", value: HomepageUrlEnum.PREFERENCES },
    { label: "User Profile", value: HomepageUrlEnum.USER },
]

const defaultPreferenceFields = (user: any) => ({
    range: "",
    resolution: "",
    blockId: null,
    homepage: "",
    userId: user.user_id
})

const findPreferenceFields = (pref: UserPreferenceModel): FormRow => {
    const userId = +pref.userId
    const preferenceId = pref.preferenceId
    const range = pref.time?.range ?? ""
    const resolution = pref.time?.resolution ?? ""

    let homepage = ""
    let blockId = null

    if (pref.defaultView) {
        const isIdPath = pref.defaultView.match(/[0-9]+/)
        blockId = isIdPath ? +isIdPath[0] : null
        homepage = pref.defaultView.slice().replace(/[0-9]+/, ":block_id")
    }

    return {
        userId,
        preferenceId,
        range,
        resolution,
        blockId,
        homepage
    }
}

const compileRecordForSubmission = (formRow: FormRow) => {
    const time = formRow.range ? { range: formRow.range, resolution: formRow.resolution } : null

    let defaultView = null
    if (formRow.blockId && formRow.homepage) {
        defaultView = formRow.homepage.slice().replace(":block_id", `${formRow.blockId}`)
    } else if (formRow.homepage) {
        defaultView = formRow.homepage
    }

    return {
        user_id: formRow.userId,
        preference_id: formRow.preferenceId,
        default_view: defaultView,
        time,
    }
}

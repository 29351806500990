import { Form, Space, TreeSelect } from 'antd';
import React, { useMemo } from 'react';

import './ReportFilter.scss'
import { defaultFilterOption } from '../../../utils/helpers';
import { getBlocksTreeData } from '../../../utils/blocks';
import { entityBlock } from '../../../../store/old/Blocks/Blocks.selector';
import { useDispatch, useSelector } from 'react-redux';
import AukButton from '../../../components/AukButton';
import { CaretRightOutlined, EllipsisOutlined } from '@ant-design/icons';
import { rootIssues } from '../../../../store/old/Issues/Issues.selector';

import { generateIssuesTreeData } from '../../../../legacy/routes/IssueManagement/IssuesTreeData';
import { setIssueReportFilter } from '../../../../store/old/UI/Report/Report.action';
import ReportRangePicker from './ReportRangePicker';

const VALIDATE = {
    DATE_RANGE: [
        {
            required: true,
            message: 'First name is required.',
        },
        (_form) => ({
            validator: (_, value) => {
                const [start, end] = value;
                
                if (!start || !end) {
                    return Promise.reject(new Error('Date range is required'));
                }
                return Promise.resolve();
            },
        })
    ],
    ISSUE_ID: [
        {
            required: true,
            message: 'Issue is required.',
        }
    ],
    BLOCK: [
        {
            required: true,
            message: 'Asset is required.',
        }
    ],
}

const IssueSeriesFilter = (props) => {
    const dispatch = useDispatch();
    const issuesState = useSelector(appState => appState.issues);
    const filterState = useSelector(appState => appState.ui.report.issueFilter);
    const rootBlock = useSelector(entityBlock);
    const [form] = Form.useForm();

    const blocksTreeData = useMemo(() => {
        return [getBlocksTreeData(rootBlock, { disabledAccessor: (n) => (!n.asset && !n.children.length) || (n.asset && !n.asset._primaryChart)})]
    }, [rootBlock]);

    const issuesTreeData = useMemo(() => {
        return rootIssues(issuesState).map((node) => generateIssuesTreeData(node));
    }, [issuesState]);

    return (
        <Space>
            <Form
                name="issue-series-filter"
                id="issueSeriesFilter"
                form={form}
                className="report-filter"
                onFinish={() => {
                    const formState = form.getFieldsValue(true);
                    dispatch(setIssueReportFilter(formState));
                }}
            >
                <Form.Item
                    colon={false}
                    label={'Date Range'}
                    name="dateRange"
                    initialValue={filterState.dateRange}
                    rules={VALIDATE.DATE_RANGE}
                >
                    <ReportRangePicker />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label={'Asset'}
                    name="blockIds"
                    initialValue={filterState.blockIds}
                    rules={VALIDATE.BLOCK}
                >
                    <TreeSelect
                        maxTagCount={1}
                        maxTagPlaceholder={<EllipsisOutlined />}
                        treeCheckable
                        allowClear
                        style={{width: 280}}
                        treeDefaultExpandAll
                        showSearch
                        filterTreeNode={defaultFilterOption}
                        treeData={blocksTreeData}
                    />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label={'Issue'}
                    name="issueId"
                    initialValue={filterState.issueId}
                    rules={VALIDATE.ISSUE_ID}
                >
                    <TreeSelect
                        style={{width: 280}}
                        treeData={issuesTreeData}
                        treeDefaultExpandAll
                        showSearch
                        filterTreeNode={defaultFilterOption}
                    />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label=" "
                >
                    <AukButton.Blue htmlType="submit" ghost>
                        <CaretRightOutlined/> Apply
                    </AukButton.Blue>
                </Form.Item>
            </Form>
        </Space>
    )
}

export default IssueSeriesFilter;
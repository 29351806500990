export const AssetPageConstants = {
    ACTIONS: {
        SET_ASSET_EVENTS_FILTER: 'SET_ASSET_EVENTS_FILTER',
        SET_ASSET_EVENTS_DATA : 'SET_ASSET_EVENTS_DATA',
        SET_ASSET_EVENTS_OEE_DATA: 'SET_ASSET_EVENTS_OEE_DATA',
        SET_ASSET_EVENTS_LABELS_DATA: 'SET_ASSET_EVENTS_LABELS_DATA',
        SET_ASSET_EVENTS_PAGE_DATA: 'SET_ASSET_EVENTS_PAGE_DATA',
        FETCH_ASSET_EVENTS_PAGE_DATA: 'FETCH_ASSET_EVENTS_PAGE_DATA',
        // FETCH_ASSET_EVENTS_OEE: 'FETCH_ASSET_EVENTS_OEE',
        // FETCH_ASSET_EVENTS_LABELS: 'FETCH_ASSET_EVENTS_LABELS',
        // FETCH_ASSET_EVENTS: 'FETCH_ASSET_EVENTS',
        EVENT_CREATE_LABEL: 'EVENT_CREATE_LABEL',
        EVENT_UPDATE_LABEL: 'EVENT_UPDATE_LABEL',
        EVENT_DELETE_LABEL: 'EVENT_DELETE_LABEL'
    }
}
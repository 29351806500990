import { keys, uniq } from 'lodash';

export const permittedIssuesByBlock = (issues, blockId, blocksIssuesExclusions) => {
    return keys(issues)
        .filter((issue_id) => !(blocksIssuesExclusions[blockId] && blocksIssuesExclusions[blockId][issue_id]))
        .map((issue_id) => +issue_id);
};

export const permittedIssuesByBlocks = (issues, blockIds, blocksIssuesExclusions) => {
    return uniq(
        blockIds
            .map((id) => permittedIssuesByBlock(issues, id, blocksIssuesExclusions))
            .reduce((acc, curr) => acc.concat(...curr), [])
    );
};

export const isQualityIssue = (issue) => {
    if (!issue) return false;
    return issue.oee === 'rj' || issue.oee === 'rw';
};

export const isPermittedIssue = (blockId, issueId, blocksIssuesExclusions) => {
    return blocksIssuesExclusions[blockId] ? !blocksIssuesExclusions[blockId][issueId] : true;
}
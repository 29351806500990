export enum ROUTES {
    BASE = "/",
    LOGIN = "/login",
    REGISTER = "/register",
    RESET_PASSWORD = "/reset_password",
    CHANGE_PASSWORD = "/user/reset",
    VERIFY_EMAIL = "/verify_email",
    JOIN_ENTITY = "/join_company",
    SETUP_ENTITY = "/setup_company",
    SETUP_GATEWAY = "/setup_gateway",
    USER = "/user",
    DASHBOARD = "/dashboard",
    UTILIZATION = "/utilization",
    STANDARD_TIMES = "/standard_time",
    TREND = "/trend_analysis",
    REGRESSION = "/regression_analysis",
    HARDWARE_MANAGEMENT = "/hardware_management",
    ASSET_MANAGEMENT = "/asset_management",
    ASSET = "/asset",
    NOTIFICATIONS = "/notifications",
    USER_MANAGEMENT = "/user_management",
    PARETO = "/pareto_analysis",
    ISSUE_MANAGEMENT = "/issue_management",
    SKU_MANAGEMENT = "/sku_management",
    ISSUE_TRACKER = "/issue_tracker",
    ENTITY_MANAGEMENT = "/entity_management",
    SCHEDULING = "/scheduling",
    MESH = "/mesh_connectivity",
    SEARCH = "/search",
    TARGET_SETTING = "/target_setting",
    PREFERENCES = "/preferences",
    BLOCK = "/block",
    PERMISSIONS_MANAGEMENT = "/permissions",
    USER_GUIDE = "/user_guide",
    PRODUCTION_ACHIEVEMENT = "production_achievement",
    OPERATOR = "/operator",
    PLANNER = "/planner",
    EVENTS = "/events",
    IMPACT = "/impact",
    GOALS = "/goals",
    TIME_BASED_REPORT = "/time_based_report",
    SKU_REPORT = "/sku_report",
    ISSUE_REPORT = "/issue_report",
    INVITE = "/invite",
    NOT_FOUND = "/page_not_found",
}

import { DatePicker, Form, Modal, Select, Space, Tag } from 'antd';
import React, { useState } from 'react';

import { uiDatetimeFormat } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import AukButton from '../../../components/AukButton';
import { LeftOutlined, RightOutlined, SettingOutlined } from '@ant-design/icons';
import { setAssetEventsFilter } from '../../../../store/old/UI/asset/asset.action';
import OeeSkuButton from '../../../components/ControlPanel/OeeSkuButton';

const AssetEventsFilter = (props) => {
    const dispatch = useDispatch();
    const filterState = useSelector(appState => appState.ui.asset.events.filter);
    const [form] = Form.useForm();

    return (
        <Space>
            <Form
                name="asset-events-filter"
                id="assetEventsFilter"
                form={form}
                className="asset-events-filter"
                onValuesChange={() => {
                    const formState = form.getFieldsValue(true);
                    dispatch(setAssetEventsFilter({...formState}));
                }}
            >
                <Form.Item
                    colon={false}
                    label={'Start'}
                    name="start"
                    initialValue={filterState.start}
                >
                    <EventsDatePicker span={filterState.span} />
                </Form.Item>
                <Form.Item
                    colon={false}
                    label={'Span'}
                    name="span"
                    initialValue={filterState.span}
                >
                    <Select allowClear={false} style={{width: 120}} options={SPAN_OPTIONS}/>
                </Form.Item>
                <Form.Item
                    colon={false}
                    label="Events Threshold"
                    name="threshold"
                    initialValue={filterState.threshold}
                >   
                    <Select options={THRESHOLD_OPTIONS}/>
                </Form.Item>
                <Form.Item
                    colon={false}
                    label=" "
                >
                    <OeeSkuButton/>
                </Form.Item>
            </Form>
        </Space>
    )
}

export default AssetEventsFilter;

const EventsDatePicker = (props) => {
    const [span_x, span_period] = props.span.split(' ');

    return (
        <div className="asset-events-filter__datepicker">
            <AukButton.Outlined
                icon={<LeftOutlined style={{fontSize: 12}} />}
                className="mr-1"
                title={`Move back ${props.span}`}
                onClick={() => { props.onChange(props.value.clone().subtract(span_x, span_period)) }}
            />
            <DatePicker
                value={props.value}
                onChange={props.onChange}
                allowClear={false}
                showTime={{ format: 'HH:mm' }}
                format={uiDatetimeFormat}
            />
            <AukButton.Outlined
                icon={<RightOutlined style={{fontSize: 12}} />}
                className="ml-1"
                title={`Move forward ${props.span}`}
                onClick={() => { props.onChange(props.value.clone().add(span_x, span_period)) }}
            />
        </div>
    );
}

const SPAN_OPTIONS = [
    { value: '4 hours', label: '4 hours' },
    { value: '8 hours', label: '8 hours' },
    { value: '12 hours', label: '12 hours' }
];

const THRESHOLD_OPTIONS = [
    { value: 5, label: '5 minutes' },
    { value: 10, label: '10 minutes' },
    { value: 20, label: '20 minutes' },
    { value: 30, label: '30 minutes' },
];

import { pickBy } from 'lodash';
import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { AssetPageConstants as K } from './asset.constants';

const getInitialState = () => {
    return {
        events: {
            oee: [],
            labels: [],
            events: [],
            filter: {
                start: null,
                span: '12 hours',
                threshold: 5 // minutes
            }
        },
    };
};

export const AssetPageReducer = (
    state = { ...getInitialState() },
    action
) => {
    if (action.type === K.ACTIONS.SET_ASSET_EVENTS_FILTER) {
        return updateStoreRedux(state, {
            events: {
                ...state.events,
                filter: {
                    ...state.events.filter,
                    ...pickBy(action.payload, v => v !== undefined),
                }
            }
        });
    }

    if (action.type === K.ACTIONS.SET_ASSET_EVENTS_PAGE_DATA) {
        return updateStoreRedux(state, {
            events: {
                ...state.events,
                oee: action.payload.oee,
                labels: action.payload.labels,
                events: action.payload.events
            }
        });
    }

    // if (action.type === K.ACTIONS.SET_ASSET_EVENTS_OEE_DATA) {
    //     return updateStoreRedux(state, {
    //         events: {
    //             ...state.events,
    //             oee: action.payload.oee,
    //         }
    //     });
    // }

    // if (action.type === K.ACTIONS.SET_ASSET_EVENTS_LABELS_DATA) {
    //     return updateStoreRedux(state, {
    //         events: {
    //             ...state.events,
    //             labels: action.payload.labels,
    //         }
    //     });
    // }

    // if (action.type === K.ACTIONS.SET_ASSET_EVENTS_DATA) {
    //     return updateStoreRedux(state, {
    //         events: {
    //             ...state.events,
    //             events: action.payload.events,
    //         }
    //     });
    // }

    return updateStoreRedux(state, {});
};

import { AssetPageConstants as K } from './asset.constants';

export const setAssetEventsFilter = ({start, span, threshold}) => {
    return {
        type: K.ACTIONS.SET_ASSET_EVENTS_FILTER,
        payload: { start, span, threshold }
    }
}

export const setAssetEventsPageData = ({oee, labels, events}) => {
    return {
        type: K.ACTIONS.SET_ASSET_EVENTS_PAGE_DATA,
        payload: { oee, labels, events }
    }
}

export const fetchAssetEventsPageData = (entity_id, asset_id, date_range, resolution, conditions, sku_oee, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_EVENTS_PAGE_DATA,
        payload: { entity_id, asset_id, date_range, resolution, conditions, sku_oee },
        callback,
    };
};

// export const fetchAssetEventsOee = (entity_id, asset_id, query, callback) => {
//     return {
//         type: K.ACTIONS.FETCH_ASSET_EVENTS_OEE,
//         payload: { entity_id, asset_id, query },
//         callback,
//     };
// };

// export const fetchAssetEventsLabels = (entity_id, asset_id, query, callback) => {
//     return {
//         type: K.ACTIONS.FETCH_ASSET_EVENTS_LABELS,
//         payload: { entity_id, asset_id, query },
//         callback,
//     };
// }

// export const fetchAssetEvents = (entity_id, asset_id, query, callback) => {
//     return {
//         type: K.ACTIONS.FETCH_ASSET_EVENTS,
//         payload: { entity_id, asset_id, query },
//         callback,
//     };
// }

export const setAssetEventsData = (events) => {
    return {
        type: K.ACTIONS.SET_ASSET_EVENTS_DATA,
        payload: { events }
    }
}

export const setAssetEventsOeeData = (oee) => {
    return {
        type: K.ACTIONS.SET_ASSET_EVENTS_OEE_DATA,
        payload: { oee }
    }
}

export const setAssetEventsLabelsData = (labels) => {
    return {
        type: K.ACTIONS.SET_ASSET_EVENTS_LABELS_DATA,
        payload: { labels }
    }
}

export const createLabelFromEvent = (entity_id, asset_id, params, callback) => {
    return {
        type: K.ACTIONS.EVENT_CREATE_LABEL,
        payload: { entity_id, asset_id, params },
        callback
    }
}

export const updateLabelFromEvent = (entity_id, asset_id, params, options = {}, callback) => {
    return {
        type: K.ACTIONS.EVENT_UPDATE_LABEL,
        payload: { entity_id, asset_id, params, options },
        callback
    }
}

export const deleteLabelFromEvent = (entity_id, asset_id, label_id, callback) => {
    return {
        type: K.ACTIONS.EVENT_DELETE_LABEL,
        payload: { entity_id, asset_id, label_id },
        callback
    }
}
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu, Switch } from 'antd';
import { FilterOutlined, InfoCircleOutlined, TableOutlined, UnorderedListOutlined } from '@ant-design/icons';

// COMPONENT
import AukButton from '../../components/AukButton';
import AukTooltip from '../../components/AukTooltip';
import CsvFileUpload from '../../components/CSVFileUpload';
import ModalStandardTime from './Modules/ModalStandardTime';
import OeeGridCell from './components/OeeGridCell';
import OeeHeaderCell, { CellLegend } from './components/OeeHeaderCell';
import VSpreadsheet from '../../components/VSpreadsheet';
import { SPAWrapper } from '../../components/SPAWrapper';
import { NoData } from '../../components/None';
import { saveCsv } from '../../utils/dataExports';
import { arraySkus, skusSelector } from '../../../store/old/Sku/Sku.selector';
import VTable from '../../components/VTable';
import InputWithSubmit from '../../components/AukInput/InputWithSubmit'

import { bulkCreateUpdateSkuCharts, createSkuChart, fetchSkuCharts, updateSkuChart } from '../../../store/old/SkuCharts/SkuCharts.action';

// SELECTOR
import { assetsOrderedByBlock, assetsSelector } from '../../../store/old/Assets/Assets.selector';

// STYLE
import './index.scss';
import { withEntity } from '../../Wrappers/HOCs/withEntity';
import { errorFlash, flash } from '../../components/Flash';
import { withSkus } from '../../Wrappers/HOCs/withSkus';
import AukFilterSelectMulti from '../../components/AukFilterSelectMulti';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { toNumber } from 'lodash';
import { entityBlock } from '../../../store/old/Blocks/Blocks.selector';

const CSV = {
    UPLOAD: 'upload',
    TEMPLATE: 'template',
};

const VIEW = {
    LIST: 'list',
    GRID: 'grid',
};

const VALIDATE = {
    STD_TIME: [
        (_form) => ({
            validator: (_, value) => {
                const val = toNumber(value)
                if (!isFinite(val)) {
                    return Promise.reject(new Error('Must be a number'));
                }

                if (val < 0) {
                    return Promise.reject(new Error('Must be greater than 0'));
                }

                return Promise.resolve();
            },
        }),
    ],
}

const getSkuAssetChartKey = (sku_id, chart_id) => `${sku_id}-${chart_id}`
export const stdTimeMaxLength = 7;

const SkuStandardTimes = (props) => {
    const { entity: { entity_id }} = props;
    const dispatch = useDispatch();

    const authUser = useSelector(appState => appState.auth.user)
    const skusResource = useSelector(skusSelector)
    const assetsResource = useSelector(assetsSelector)
    const rootBlock = useSelector(entityBlock)
    const skuCharts = useSelector(appState => appState.skuCharts.skuCharts)
    const _skus = useMemo(() => arraySkus(skusResource), [skusResource])
    const _assets = useMemo(() => assetsOrderedByBlock(authUser, rootBlock), [authUser, rootBlock, assetsResource])

    useEffect(() => {
        dispatch(fetchSkuCharts(entity_id, { primary: true }))
    }, []);

    const [assetsSelection, setAssetsSelection] = useState(_assets.map((a) => a.asset_id));
    const [skusSelection, setSkusSelection] = useState(_skus.map((s) => s.sku_id));

    const [view, setView] = useState(VIEW.LIST);
    const [showCsvUpload, setShowCsvUpload] = useState(false);
    const [csvTemplateString, setCSVTemplateString] = useState('');

    const assets = useMemo(() => {
        const selectionIds = new Set(assetsSelection);
        return _assets.filter((a) => selectionIds.has(a.asset_id));
    }, [_assets, assetsSelection]);

    const skus = useMemo(() => {
        const selectionIds = new Set(skusSelection);
        return _skus.filter((s) => selectionIds.has(s.sku_id));
    }, [_skus, skusSelection]);

    const assetsOptions = useMemo(
        () => _assets.map((a) => ({ value: a.asset_id, title: a.asset_name })),
        [_assets]
    );

    const skusOptions = useMemo(
        () => _skus.map((s) => ({ value: s.sku_id, title: s.codeName })),
        [_skus]
    );

    const generateTemplate = useCallback(() => {
        const header =
      'ID,Asset Name,SKU Name,Auto Cycle Time (y/n),Standard Time (/s)';

        const rows = _assets
            .map((asset) => {
                return _skus.map((sku) => {
                    const skuChart = skuCharts[getSkuAssetChartKey(sku.sku_id, asset._primaryChart.chart_id)]
                    return [
                        `${sku.sku_id}_${asset.asset_id}_${asset._primaryChart.chart_id}`,
                        `"${asset.asset_name}"`,
                        `"${sku.codeName}"`,
                        skuChart ? (skuChart.auto_update ? 'y' : 'n') : '',
                        skuChart ? skuChart.std_time : '',
                    ];
                });
            })
            .reduce((acc, curr) => acc.concat(curr), [])
            .map((row) => row.join(','))
            .join('\n');

        const str = header + '\n' + rows;
        setCSVTemplateString(str);
        return str;
    }, [_assets, _skus, skuCharts]);

    const downloadTemplate = useCallback(() => {
        const template = csvTemplateString ? csvTemplateString : generateTemplate();

        saveCsv(template, 'assets_skus_std_times.csv');
    }, [csvTemplateString, skuCharts]);

    const csvDropdownButton = useMemo(() => {
        const csvOptions = [
            { key: CSV.UPLOAD, label: 'Upload standard times (.csv)' },
            { key: CSV.TEMPLATE, label: 'Download template (.csv)' },
        ];

        const handleClickMenu = ({ key }) => {
            switch (key) {
            case CSV.UPLOAD:
                return setShowCsvUpload(true);
            case CSV.TEMPLATE:
                return downloadTemplate();
            default:
                return;
            }
        };

        return (
            <AukTooltip.Help title="Bulk Create">
                <AukButton.Dropdown
                    className="auk-button--round p-0 m-0 ml-2"
                    icon={<i className="fas fa-upload" />}
                    overlay={
                        <Menu onClick={handleClickMenu}>
                            {csvOptions.map((o) => (
                                <Menu.Item key={o.key}>{o.label}</Menu.Item>
                            ))}
                        </Menu>
                    }
                />
            </AukTooltip.Help>
        );
    }, [csvTemplateString, skuCharts]);

    const parseCsvUpload = useCallback(
        (rows) => {
            try {
                const getRowValues = (row, index) => {
                    if (row.length !== 5) throw { message: `File is not valid format` };
                    const [uuid, _asset, _sku, _autoUpdate, _st] = row;

                    const [sku_id, asset_id, chart_id] = uuid.replace('"', '').split('_');
                    const auto_update = _autoUpdate === 'y';
                    const std_time = +_st.trim().replace(/\r/, '');

                    const key = getSkuAssetChartKey(sku_id, chart_id);
                    const sku_chart_id = skuCharts[key]?.sku_chart_id;

                    if (
                        !new Set(['y', 'n', '']).has(_autoUpdate.toLowerCase()) ||
                        !(
                            _st.trim().length <= stdTimeMaxLength &&
                        isFinite(std_time) &&
                        +std_time >= 0
                        )
                    ) {
                        throw { message: `Invalid field, please check row ${index + 2}` };
                    }

                    return { asset_id, chart_id, sku_id, auto_update, std_time, sku_chart_id };
                };

                const dataset = rows
                    .map(getRowValues)
                    .filter(
                        ({ sku_id, auto_update, std_time, sku_asset_id }) => {
                            if (sku_asset_id) {
                                const key = getSkuAssetChartKey(sku_id, chart_id);
                                const record = skuCharts[key]
                                return (
                                    record.auto_update !== auto_update || record.std_time !== +std_time
                                );
                            } else {
                                return auto_update !== false || std_time;
                            }
                        }
                    )
                    .map((row) => {
                        if (row.sku_chart_id && row.std_time === '') {
                            row.std_time = 0;
                        }
                        return row;
                    });

                if (!dataset.length) {
                    flash({ message: 'Nothing to update', status: 'info' });
                    return;
                }

                dispatch(
                    bulkCreateUpdateSkuCharts(entity_id, dataset, () => {
                        setShowCsvUpload(false);
                        flash({ message: 'Upload success'})
                    })
                );
            } catch (e) {
                errorFlash(e);
            }
        },
        [skuCharts]
    );

    const data = useMemo(() => {
        if (assets.length && skus.length) {
            if (view === VIEW.GRID) {
                return skus.map((sku) => {
                    return assets
                        .filter((asset) => asset._primaryChart)
                        .map((asset) => {
                            const key = getSkuAssetChartKey(sku.sku_id, asset._primaryChart.chart_id)
                            return skuCharts[key] || { sku, asset, chart: asset._primaryChart };
                        });
                });
            }

            return assets
                .filter((asset) => asset._primaryChart)
                .map((asset) => {
                    return skus.map((sku) => ({
                        sku,
                        asset,
                        chart: asset._primaryChart,
                        skuChart: skuCharts[getSkuAssetChartKey(sku.sku_id, asset._primaryChart.chart_id)]
                    }));
                })
                .reduce((acc, curr) => acc.concat(curr), []);
        }

        return []
    }, [assets, skus, skuCharts, view])

    const body = useMemo(() => {
        if (assets.length && skus.length) {
            if (view === VIEW.GRID) {
                return <AssetSkuGrid data={data} columns={assets} rows={skus} />
            }

            return <AssetSkuList data={data} />
        }
        return <NoData
            style={{ flexGrow: 1 }}
            description="Select at least one asset and one SKU to continue"
        />
    }, [data, assets, skus, view])

    return (
        <SPAWrapper className="st-tab-content-container">
            <div className="d-flex mb-2">
                <div className="d-flex w-100">
                    <AukFilterSelectMulti
                        className="st-filter"
                        checkAllMenuOption={true}
                        data={assetsOptions}
                        onChange={setAssetsSelection}
                        maxTagCount={0}
                        maxTagPlaceholder={null}
                        allowClear={false}
                        suffixIcon={
                            <>
                                <FilterOutlined className="mr-2" /> Select Assets
                            </>
                        }
                    />
                    <AukFilterSelectMulti
                        className="st-filter"
                        checkAllMenuOption={true}
                        data={skusOptions}
                        onChange={setSkusSelection}
                        maxTagCount={0}
                        allowClear={false}
                        suffixIcon={
                            <>
                                <FilterOutlined className="mr-2" /> Select SKUs
                            </>
                        }
                    />
                </div>
                <div className="d-flex">
                    <AukTooltip.Help title="View list">
                        <AukButton.Blue
                            ghost={view !== VIEW.LIST}
                            icon={<UnorderedListOutlined />}
                            onClick={() => setView(VIEW.LIST)}
                        />
                    </AukTooltip.Help>
                    <AukTooltip.Help title="View table">
                        <AukButton.Blue
                            className="mr-1"
                            ghost={view !== VIEW.GRID}
                            icon={<TableOutlined />}
                            onClick={() => setView(VIEW.GRID)}
                        />
                    </AukTooltip.Help>
                    {csvDropdownButton}
                </div>
            </div>
            <Layout className="d-flex w-100 mt-2" style={{ flexGrow: 1 }}>
                {body}
            </Layout>
            <CsvFileUpload
                visible={showCsvUpload}
                onCancel={() => setShowCsvUpload(false)}
                onSubmit={parseCsvUpload}
                rowTransform={(s) => s.replace(/(".*?")/g, '').split(',')}
            />
        </SPAWrapper>
    );
};

export default withEntity(withSkus(SkuStandardTimes));

const AssetSkuGrid = (props) => {
    const modalRef = useRef(null);

    const openModal = (
        action,
        selectedAsset,
        selectSkuChart,
    ) => {
        modalRef.current &&
        modalRef.current.changeStateModalStandardTime(
        action,
        selectedAsset,
        selectSkuChart,
      );
    };

    const renderBodyCell = useCallback(({ columnIndex, key, rowIndex, style, value }) => {
            const { asset, sku, chart } = value;
            if (!value.sku_chart_id) {

                const dataPlaceholder = {
                    asset,
                    sku,
                    chart,
                    std_time: 0,
                    auto_update: false,
                }

                return (
                    <OeeGridCell
                        key={key}
                        isEnabled={asset.speed}
                        data={dataPlaceholder}
                        handleOpenModalStandardTime={() => {
                            openModal('create-update-sku-chart', asset, { sku_id: sku.sku_id, chart_id: chart.chart_id, sku, chart });
                        }}
                    />
                );
            }

            
            return (
                <OeeGridCell
                    key={key}
                    isEnabled={asset.speed}
                    data={value}
                    handleOpenModalStandardTime={() => { openModal('create-update-sku-chart', asset, value) }}
                />
            );
    }, [])

    const renderHeaderCell = useCallback(({ columnIndex, key, rowIndex, style, value }) => {
            return (
                <OeeHeaderCell
                    asset={value}
                    handleOpenModalStandardTime={(assetValue) => {
                        openModal('update-asset', assetValue);
                    }}
                />
            );
    }, [])

    const renderAxisCell = useCallback(({ columnIndex, key, rowIndex, style, value }) => {
        return (
            <div
                title={value.codeName}
                className="axis-cell-content"
                style={{ height: 50, width: 180 }}
            >
                {value.codeName}
            </div>
        );
    }, [])

    return (
        <>
            <div style={{ position: 'relative', height: "calc(100% - 16px)" }}>
                <CellLegend/>
                <VSpreadsheet
                    cellWidth={100}
                    cellHeight={50}
                    overflowHeaderHeight={120}
                    headerHeight={120}
                    axisWidth={180}
                    data={props.data}
                    columns={props.columns}
                    columnAccessor={(asset) => asset}
                    columnKeyAccessor={(a) => a.asset_id}
                    rows={props.rows}
                    rowAccessor={(sku) => sku}
                    rowKeyAccessor={(sku) => sku.sku_id}
                    renderBodyCell={renderBodyCell}
                    renderHeaderCell={renderHeaderCell}
                    renderLeftSideCell={renderAxisCell}
                    showMarkingLines={false}
                    highlightOnHover={false}
                />
            </div>
            <ModalStandardTime ref={modalRef} />
        </>
    );
};

const AssetSkuList = ({ data }) => {
    const dispatch = useDispatch();
    const { entity_id } = useSelector(currentEntitySelector);

    const save = useCallback(
        (asset_id, chart_id, sku_id, params) => {
            dispatch(
                params.sku_chart_id ? 
                    updateSkuChart(entity_id, asset_id, chart_id, sku_id, params) : 
                    createSkuChart(entity_id, asset_id, chart_id, sku_id, params)
            );
        },
        []
    );
    const columns = useMemo(
        () => [
            {
                title: 'Asset',
                render: (text, record, index) => (
                    <AukTooltip.Info title={record.asset.asset_name}>
                        <div className="text-overflow-ellipsis">
                            {record.asset.asset_name}
                        </div>
                    </AukTooltip.Info>
                ),
                sorter: (a, b) => a.asset.asset_name.localeCompare(b.asset.asset_name),
                width: 300,
            },
            {
                title: 'SKU',
                render: (text, record, index) => (
                    <AukTooltip.Info title={record.sku.codeName}>
                        <div className="text-overflow-ellipsis">{record.sku.codeName}</div>
                    </AukTooltip.Info>
                ),
                sorter: (a, b) => a.sku.codeName.localeCompare(b.sku.codeName),
                width: 300,
            },
            {
                title: 'Auto Cycle Time',
                render: (text, record, index) => {
                    return <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked={record.skuChart?.auto_update}
                        onChange={(value) => {
                            save(
                                record.asset.asset_id,
                                record.chart.chart_id,
                                record.sku.sku_id,
                                { 
                                    auto_update: value,
                                    sku_chart_id: record.skuChart?.sku_chart_id
                                }
                            )
                        }}
                    />
                },
                width: 180,
            },
            {
                title: (
                    <div className="d-flex">
                        Standard Time (s){' '}
                        <AukTooltip.Help title="Set as 0 to remove">
                            <span className="d-flex align-items-center ml-2">
                                <InfoCircleOutlined />
                            </span>
                        </AukTooltip.Help>
                    </div>
                ),
                render: (text, record, index) => {
                    return <InputWithSubmit
                        rules={VALIDATE.STD_TIME}
                        value={record.skuChart?.std_time}
                        submit={(value) => {
                            if (!value) return;
                            save(
                                record.asset.asset_id,
                                record.chart.chart_id,
                                record.sku.sku_id,
                                { 
                                    std_time: +value,
                                    sku_chart_id: record.skuChart?.sku_chart_id
                                }
                            )
                        }}
                    />
                },
                width: 180,
            },
        ],
        []
    );
    return (
        <div className="d-flex flex-column h-100">
            <VTable
                rowHeight={60}
                columns={columns}
                dataSource={data}
                rowKey={({ asset, sku }) => `${sku.sku_id}-${asset.asset_id}`}
            />
        </div>
    );
};
import { AssetPageConstants as K } from './asset.constants'
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { errorFlash } from '../../../../legacy/components/Flash';
import { api_getAssetOEEData } from '../../Data/Data.services';
import { setAssetEventsPageData } from './asset.action';
import { OEEFactory } from '../../../../legacy/utils/oee';
import { api_createLabel, api_deleteLabel, api_getAssetLabelEvents, api_getAssetLabels, api_updateLabel } from '../../Labels/Labels.services';
import { Label, parseLabelArguments } from '../../../../legacy/models';
import { compileLabel } from '../../../../legacy/utils/labels';

function* handleGetAssetEventsPageData(action) {
    try {
        const { entity_id, asset_id, date_range, resolution, conditions, sku_oee } = action.payload;
        const { res_x, res_period } = resolution;

        const oeeQuery = { date_range, res_x, res_period, sku_oee };
        const labelsQuery = { date_range };
        const eventsQuery = { date_range, conditions: JSON.stringify(conditions) };

        const [_oee, _labels, _events] = yield all([
            call(api_getAssetOEEData, entity_id, asset_id, oeeQuery),
            call(api_getAssetLabels, entity_id, asset_id, labelsQuery),
            call(api_getAssetLabelEvents, entity_id, asset_id, eventsQuery)
        ]);

        const oee = OEEFactory(_oee);
        const labels = _labels.map(l => new Label(...parseLabelArguments(l)));
        const events = _events.map(v => ({ ...v, from: new Date(v.from), to: new Date(v.to)}));

        yield put(setAssetEventsPageData({ oee, labels, events }))

        action.callback && action.callback(res);
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAssetEventsPageDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_EVENTS_PAGE_DATA,
        handleGetAssetEventsPageData
    );
}

// function* handleGetAssetEventsOee(action) {
//     try {
//         const { entity_id, asset_id, query } = action.payload;

//         const res = yield call(api_getAssetOEEData, entity_id, asset_id, query);

//         yield put(setAssetEventsOeeData(OEEFactory(res)));

//         action.callback && action.callback(res);
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* getAssetEventsOeeSaga() {
//     yield takeLatest(
//         K.ACTIONS.FETCH_ASSET_EVENTS_OEE,
//         handleGetAssetEventsOee
//     );
// }

// function* handleGetAssetEventsLabels(action) {
//     try {
//         const { entity_id, asset_id, query } = action.payload;

//         const res = yield call(api_getAssetLabels, entity_id, asset_id, query);
//         const labels = res.map(l => new Label(...parseLabelArguments(l)));

//         yield put(setAssetEventsLabelsData(labels));

//         action.callback && action.callback(res);
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* getAssetEventsLabelsSaga() {
//     yield takeLatest(
//         K.ACTIONS.FETCH_ASSET_EVENTS_LABELS,
//         handleGetAssetEventsLabels
//     );
// }

// function* handleGetAssetEvents(action) {
//     try {
//         const { entity_id, asset_id, query } = action.payload;

//         const res = yield call(api_getAssetLabelEvents, entity_id, asset_id, query);

//         yield put(setAssetEventsData(res.map(v => ({ ...v, from: new Date(v.from), to: new Date(v.to)}))));

//         action.callback && action.callback(res);
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* getAssetEventsSaga() {
//     yield takeLatest(
//         K.ACTIONS.FETCH_ASSET_EVENTS,
//         handleGetAssetEvents
//     );
// }

function* handleCreateLabelFromEvent(action) {
    try {
        const { asset_id, params } = action.payload;
        const { values, from, to } = params;
        const data = compileLabel({ values, from, to });
        yield call(api_createLabel, asset_id, data);

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* createLabelFromEventSaga() {
    yield takeLatest(
        K.ACTIONS.EVENT_CREATE_LABEL,
        handleCreateLabelFromEvent
    );
}

function* handleEventPageUpdateLabel(action) {
    try {
        const { asset_id, params, options } = action.payload;
        const { values, from, to, label_id } = params;
        const data = compileLabel({ label_id, values, from, to }, options);
        yield call(api_updateLabel, asset_id, data);

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
        yield action.callback(false);
    }
}

export function* updateLabelFromEventSaga() {
    yield takeLatest(
        K.ACTIONS.EVENT_UPDATE_LABEL,
        handleEventPageUpdateLabel
    );
}

function* handleEventPageDeleteLabel(action) {
    try {
        const { asset_id, label_id } = action.payload;
        yield call(api_deleteLabel, asset_id, label_id);

        if (action.callback) {
            yield action.callback();
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* deleteLabelFromEventSaga() {
    yield takeLatest(
        K.ACTIONS.EVENT_DELETE_LABEL,
        handleEventPageDeleteLabel
    );
}
import { TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import './IssueTreeSelect.scss';
import { useSelector } from 'react-redux';
import { generatePermittedIssuesTreeData } from '../../IssueManagement/IssuesTreeData';
import { regexMatch } from '../../../utils/helpers';
import classNames from 'classnames';
import { isPermittedIssue } from '../../../utils/issues';

const IssueTreeSelect = (props) => {
    const issuesState = useSelector(appState => appState.issues);
    const blocksState = useSelector(appState => appState.blocks);

    const [value, setValue] = useState(props.value);

    const onChange = (val) => {
        props.onChange(val);
        setValue(val);
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    
    // v2: to narrow down issue tree, supply props.categories
    const treeData = useMemo(() => {
        return generatePermittedIssuesTreeData(
            issuesState,
            [props.asset.block_id],
            blocksState.blocks_issues_exclusions
        );;
    }, [blocksState]);

    const colorClass = useMemo(() => {
        if (!value) return '';

        const issue = issuesState.issues[value];

        if (!issue) return `auk-issue-tree-select--bg-${props.defaultIssue.oee}`;
        
        return `auk-issue-tree-select--bg-${issue.oee}`;
    }, [value]);



    return (
        <TreeSelect
            treeIcon
            showSearch
            className={classNames("auk-issue-tree-select", colorClass)}
            value={!value ? 
                undefined : 
                isPermittedIssue(props.asset.block_id, value, blocksState.blocks_issues_exclusions) && issuesState.issues[value] ? 
                    value : 
                    props.defaultIssue?.name
            }
            style={{ ...props.style, width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={treeData}
            treeDefaultExpandAll
            onChange={onChange}
            filterTreeNode={(input, node) => regexMatch(node.title, input, { escape: true })}
            placeholder="Unlabelled"
            allowClear={!props.showArrow}
            showArrow={props.showArrow}
        />
    );
}

export default IssueTreeSelect;

// const generateEventIssueTreeData = (
//     issues,
//     block_ids,
//     blocks_issues_exclusions
// ) => {
//     const { issues: issuesResource } = issues;
//     const mainIssues = rootIssues(issues);

//     const permittedIssues = permittedIssuesByBlocks(
//         issuesResource,
//         block_ids,
//         blocks_issues_exclusions
//     );

//     const permittedIssuesParents = permittedIssues
//         .map((issue_id) => issuesResource[issue_id].parents)
//         .reduce((acc, curr) => acc.concat(...curr), []);

//     const issuesToDisplay = new Set([
//         ...permittedIssues,
//         ...permittedIssuesParents,
//     ]);

//     const generateTreeData = (node) => {
//         const { name: title, issue_id, color, hierarchy_level } = node;
//         const icon = hierarchy_level === 2 && (
//             <span style={{ fontSize: 14, color }}>
//                 <i className="fas fa-square" />
//             </span>
//         );
//         const disabled =
//       hierarchy_level === 1 || !permittedIssues.includes(issue_id);
//         if (node.children && !node.children.length) {
//             return {
//                 label: title,
//                 title,
//                 value: issue_id,
//                 key: issue_id,
//                 icon,
//                 disabled,
//             };
//         }
//         const children = node.children
//             .filter((c) => issuesToDisplay.has(c.issue_id))
//             .map((child) => generateTreeData(child));

//         return {
//             label: title,
//             title,
//             value: issue_id,
//             key: issue_id,
//             children,
//             icon,
//             disabled,
//         };
//     };

//     return mainIssues.map((i) => generateTreeData(i));
// };
import * as d3 from 'd3';
import { Colors } from './Charts';

const CONSTANTS = {
    SESSION_TOKEN: 'auk_auth_user',
    REACT_TRACKING_ID: 'UA-143282956-2',
    TIME_AUTHENTICATED: 'auk_time_authenticated',
    USER_NAME_FIRST: 'auk_name_first',
    USER_NAME_LAST: 'auk_name_last',
    USER_EMAIL: 'auk_email',
    USER_ID: 'auk_id',
    USER_ROLE: 'auk_role',
    USER_IMAGE: 'auk_image',
    USER_ENTITY_ID: 'auk_entity_id',
    USER_ENTITY_NAME: 'auk_entity_name',
    USER_ENTITY_IMAGE: 'auk_entity_image',
    USER_IMAGE_BASE64: 'auk_image_base64',
    USER_ENTITY_IMAGE_BASE64: 'auk_entity_image_base64',
    USER_GATEWAY: 'auk_gateway_status',
    USER_GATEWAYS: 'auk_gateway_access',
    USER_ENTITY_DENY_ACCESS: 'auk_deny_access', // for organisations on POCs
    USER_TIMEZONE: 'auk_timezone',

    ENV_LOCAL: 'local',
    ENV_DEV: 'development',
    ENV_STAGING: 'staging',
    ENV_PRODUCTION: 'production',

    // SERVER_API: 'https://api.prod.auk.industries/',
    SERVER_API: 'https://staging.api.auk.industries/',
    SERVER_API_STAGING: 'https://staging.api.auk.industries/',
    SERVER_API_DEV: 'https://staging.api.auk.industries/',
    CMS_API: 'https://cms.auk.industries/',

    REDUX_STORE: {
        RESET: 'RESET_REDUX_STORE',
        SOFT_RESET: 'SOFT_RESET_REDUX_STORE',
        RESET_UI: 'RESET_REDUX_UI_STORE',
        SOFT_RESET_UI: 'SOFT_RESET_REDUX_UI_STORE',
        INITIALIZE: 'INITIALIZE',
        UNINITIALIZE: 'UNINITIALIZE',
        SYSTEM_DOWNTIME: 'SYSTEM_DOWNTIME',
    },

    URLS: {
        BASE: '/',

        // Main
        DASHBOARD: '/dashboard',
        SKU_ASSET_STANDARD_TIME: '/standard_time',
        TREND: '/trend_analysis',
        REGRESSION: '/regression_analysis',
        HARDWARE_MANAGEMENT: '/hardware_management',
        ASSET_MANAGEMENT: '/asset_management',
        ASSET: '/asset',
        NOTIFICATIONS: '/notifications',
        USER_MANAGEMENT: '/user_management',
        PROFILE: '/user',
        PARETO: '/pareto_analysis',
        UTILIZATION: '/utilization',
        ISSUE_MANAGEMENT: '/issue_management',
        SKU_MANAGEMENT: '/sku_management',
        ISSUETRACKER: '/issue_tracker',
        ENTITY_MANAGEMENT: '/entity_management',
        SCHEDULING: '/scheduling',
        MESH: '/mesh_connectivity',
        SEARCH: '/search',
        TARGET_SETTING: '/target_setting',
        PREFERENCES: '/preferences',
        BLOCK: '/block',
        PERMISSIONS: '/permissions',
        PRODUCTION_ACHIEVEMENT: '/production_achievement',
        PLANNER: '/planner',
        EVENTS: '/events',
        IMPACT: '/impact',
        TIME_BASED_REPORT: '/time_based_report',
        SKU_REPORT: '/sku_report',
        ISSUE_REPORT: '/issue_report',

        // Account
        LOGIN: '/login',
        REGISTER: '/register',
        RESET_PASSWORD: '/reset_password',
        CHANGE_PASSWORD: '/user/reset',
        JOIN_COMPANY: '/join_company',
        VERIFY_EMAIL: '/verify_email',

        // Devices
        GATEWAYS: '/gateways',
        SETUP_GATEWAY: '/setup_gateway',
        ADD_DEVICE: '/add_device',
        ADD_DEVICE_CHANNEL: '/add_device_channel',

        // UserGuide
        USER_GUIDE: '/user_guide',
    },

    LANGUAGES: [
        {
            id: 1,
            lang: 'en',
            name: 'English',
            nativeName: 'English',
            // flag: english,
        },
        {
            id: 2,
            lang: 'it',
            name: 'Italian',
            nativeName: 'Italiano',
            // flag: italia,
        },
        {
            id: 3,
            lang: 'id',
            name: 'Indonesian',
            nativeName: 'Bahasa Indonesia',
            // flag: indonesia,
        },
        {
            id: 4,
            lang: 'ms',
            name: 'Malay',
            nativeName: 'Bahasa Melayu',
            // flag: malaysia,
        },
        {
            id: 5,
            lang: 'th',
            name: 'Thai',
            nativeName: 'ภาษาไทย',
            // flag: thailand,
        },
        {
            id: 6,
            lang: 'zh',
            name: 'Chinese',
            nativeName: '中文',
            // flag: china,
        },
        {
            id: 7,
            lang: 'es',
            name: 'Spanish',
            nativeName: 'Español',
            // flag: mexico,
        },
        {
            id: 8,
            lang: 'ja',
            name: 'Japanese',
            nativeName: '日本語',
            // flag: japan,
        },
        {
            id: 9,
            lang: 'pt',
            name: 'Portuguese',
            nativeName: 'Português',
            // flag: portugal,
        },
        {
            id: 10,
            lang: 'de',
            name: 'German',
            nativeName: 'Deutsche',
            // flag: germany,
        },
        {
            id: 11,
            lang: 'ar',
            name: 'Arabic',
            nativeName: 'عربى',
            // flag: arab,
        },
        {
            id: 12,
            lang: 'fr',
            name: 'French',
            nativeName: 'Français',
            // flag: france,
        },
        {
            id: 13,
            lang: 'ru',
            name: 'Russian',
            nativeName: 'русский',
            // flag: russia,
        },
        {
            id: 14,
            lang: 'ko',
            name: 'Korean',
            nativeName: '한국어',
            // flag: korea,
        },
        {
            id: 15,
            lang: 'vi',
            name: 'Vietnamese',
            nativeName: 'Tiếng Việt',
            // flag: vietnam,
        },
        {
            id: 16,
            lang: 'tr',
            name: 'Turkish',
            nativeName: 'Türk',
            // flag: turkey,
        },
        {
            id: 17,
            lang: 'el',
            name: 'Greek',
            nativeName: 'Yunan',
            // flag: greece,
        },
        {
            id: 18,
            lang: 'sv',
            name: 'Swedish',
            nativeName: 'Svenska',
            // flag: sweden,
        },
    ],

    THEME: [
        {
            id: 1,
            name: 'Light',
        },
        {
            id: 2,
            name: 'Dark',
        },
    ],

    ZOOM_LEVEL: [
        {
            id: 1,
            name: '1',
        },
        {
            id: 2,
            name: '2',
        },
        {
            id: 3,
            name: '3',
        },
        {
            id: 4,
            name: '4',
        },
        {
            id: 5,
            name: '5',
        },
        {
            id: 6,
            name: '6',
        },
        {
            id: 7,
            name: '7',
        },
        {
            id: 8,
            name: '8',
        },
        {
            id: 9,
            name: '9',
        },
        {
            id: 10,
            name: '10',
        },
    ],

    VIEWS: [
        { id: 1, name: 'Dashboard', url: '/dashboard' },
        // { id: 2, name: 'Asset', url: '/asset' },
        { id: 3, name: 'Trend analysis ', url: '/trend_analysis' },
        { id: 4, name: 'Regression analysis', url: '/regression_analysis' },
        { id: 5, name: 'Notifications', url: '/NOTIFICATIONS' },
        { id: 6, name: 'User management', url: '/user_management' },
        { id: 7, name: 'Profile', url: '/profile' },
        { id: 8, name: 'Pareto analysis', url: '/pareto_analysis' },
        { id: 9, name: 'Utilization heatmap', url: '/utilization_heatmap' },
        { id: 10, name: 'Sku management', url: '/sku_management' },
        { id: 11, name: 'Issue tracker', url: '/issue_tracker' },
        { id: 12, name: 'Schedules', url: '/scheduling' },
        { id: 13, name: 'Radio Mesh', url: '/mesh_connectivity' },
        { id: 14, name: 'Target setting', url: '/target_setting' },
        { id: 15, name: 'Preferences', url: '/preferences' },
        { id: 16, name: 'Standard Time Management', url: '/standard-time' },
        { id: 17, name: 'Label Search', url: '/search' },
        { id: 18, name: 'Permission Management', url: '/permission' },
        { id: 19, name: 'User Guide', url: '/userguide' },
    ],

    MODES: {
    // dashboard modes
        DEFAULT: 'view',
        VIEW: 'view',
        EDIT: 'edit',
        EDIT_MERGE: 'edit_merge',
        EDIT_DUPLICATE: 'edit_duplicate',
        EDIT_GROUP: 'edit_group',
        EDIT_DRAW: 'edit_draw',
        ADD_OEE: 'add_oee',
        EDIT_BLOCK: 'edit_block',

        // drag modes
        DRAG_NODE: 'drag_node',
        DRAG_LINK: 'drag_link',
    },

    CHARTS: {
        TYPES: {
            COLUMN: 'column',
            LINE: 'line',
            PIE: 'pie',
            STATE: 'state',
            STACK: 'stack',
            LINE_CUMULATIVE: 'line_cumulative',
        },
        STATE: {
            ON: 'on',
            BLINK: 'blink',
            OFF: 'off',
        },
    },

    ARROWS: {
        OFFSET: {
            VERTICAL_LINE: 'offset_vertical',
            HORIZONTAL_LINE: 'offset_horizontal',
            HORIZONTAL_LINE_TRUNC_X2: 'offset_horizontal_exc_x2',
            DEFAULT: 'default',
        },
    },

    CHANNELS: {
        MODES: {
            DIGITAL_COUNT: '1a',
            STATE: '2a',
            STACKLIGHT: '2b',
            ANALOG_VOLTAGE: '3a',
            ANALOG_MAX: '4a',
            ANALOG_MIN: '5a',
        },
    },

    MAJOR_STOP_THRESHOLD: 600, // seconds

    // for status light / status bar on dashboard
    STATUS: {
        GREEN: Colors.green[0],
        YELLOW: Colors.yellow[0],
        RED: Colors.red[5],
        UNAVAILABLE: 'gray',
    },

    STATE: {
        PENDING: 'pending',
        DONE: 'done',
        ERROR: 'error',
    },

    // OEE Categories
    CAT: {
        ef: {
            // new alias for FS (used for OEE stack bar chart)
            prefix: 'EF',
            name: '[EF] Effective',
            altLabel: 'Effective', // for csv download
            color: Colors.green[0],
            csvOrder: 10,
            legendOrder: 1,
        },
        rw: {
            prefix: 'RW',
            name: '[RW] Rework',
            altLabel: 'Rework',
            color: Colors.purple[2],
            csvOrder: 9,
            legendOrder: 4,
        },
        rj: {
            prefix: 'RJ',
            name: '[RJ] Rejects',
            altLabel: 'Rejects',
            color: Colors.purple[0],
            csvOrder: 8,
            legendOrder: 8,
        },
        sl: {
            prefix: 'SL',
            name: '[SL] Speed losses (vs max)',
            altLabel: 'Speed Losses',
            color: Colors.yellow[2],
            csvOrder: 5,
            legendOrder: 3,
        },
        ms: {
            prefix: 'MS',
            name: '[MS] Minor stops (<10min)',
            altLabel: 'Minor Stops',
            color: Colors.yellow[0],
            csvOrder: 4,
            legendOrder: 7,
        },
        st: {
            prefix: 'ST',
            name: '[ST] Setup/ Changeover',
            altLabel: 'Setup/ Changeover',
            color: Colors.red[6],
            csvOrder: 6,
            legendOrder: 2,
        },
        bd: {
            prefix: 'BD',
            name: '[BD] Breakdowns',
            altLabel: 'Breakdowns',
            color: Colors.red[4],
            csvOrder: 7,
            legendOrder: 6,
        },
        uu: {
            prefix: 'UU',
            name: '[UU] Un-utilised',
            altLabel: 'Un-utilised',
            color: Colors.red[5],
            csvOrder: 3,
            legendOrder: 10,
        },
        pd: {
            prefix: 'PD',
            name: '[PD] Planned downtime',
            altLabel: 'Planned Downtime',
            color: Colors.gray[0],
            csvOrder: 2,
            legendOrder: 9,
        },
        us: {
            prefix: 'US',
            name: '[US] Unscheduled',
            altLabel: 'Unscheduled',
            color: Colors.gray[2],
            csvOrder: 1,
            legendOrder: 5,
        },
        na: {
            prefix: 'NA',
            name: '[NA] No data',
            altLabel: 'No Data',
            color: Colors.gray[4],
            csvOrder: 11,
            legendOrder: 11,
        },
    },

    OEE: {
        LOSS: {
            loa: 'Loading Loss',
            ava: 'Availability Loss',
            per: 'Performance Loss',
            qua: 'Quality Loss',
        },
        WATERFALL_CHART: [
            {
                type: 'time',
                name: 'Calendar Time',
                key: 'ct',
            },
            {
                type: 'loss',
                name: 'No data',
                loss: 'loa',
                key: 'na',
            },
            {
                type: 'loss',
                name: 'Unscheduled',
                loss: 'loa',
                key: 'us',
                issue_id: 5,
            },
            {
                type: 'loss',
                name: 'Planned Downtime',
                loss: 'loa',
                key: 'pd',
                issue_id: 6,
            },
            {
                type: 'time',
                name: 'Scheduled Production Time',
                key: 'pt',
            },
            {
                type: 'loss',
                name: 'Un-utilized',
                loss: 'ava',
                key: 'uu',
            },
            {
                type: 'loss',
                name: 'Breakdown',
                loss: 'ava',
                key: 'bd',
                issue_id: 7,
            },
            {
                type: 'loss',
                name: 'Setup/ Changeover',
                loss: 'ava',
                key: 'st',
                issue_id: 8,
            },
            {
                type: 'time',
                name: 'Gross Operating Time',
                key: 'gt',
            },
            {
                type: 'loss',
                name: 'Minor stops',
                loss: 'per',
                key: 'ms',
                issue_id: 9,
            },
            {
                type: 'loss',
                name: 'Speed losses',
                loss: 'per',
                key: 'sl',
                issue_id: 10,
            },
            {
                type: 'time',
                name: 'Net Operating Time',
                key: 'nt',
            },
            {
                type: 'loss',
                name: 'Rejects',
                loss: 'qua',
                key: 'rj',
                issue_id: 11,
            },
            {
                type: 'loss',
                name: 'Reworks',
                loss: 'qua',
                key: 'rw',
                issue_id: 12,
            },
            {
                type: 'time',
                name: 'Effective',
                key: 'ef',
            },
        ],
    },

    PERIODS: [
        {
            id: -2,
            size: 15,
            value: d3.timeSecond,
            label: 'Last 15 seconds',
            unit: 'seconds',
        },
        {
            id: -1,
            size: 30,
            value: d3.timeSecond,
            label: 'Last 30 seconds',
            unit: 'seconds',
        },
        {
            id: 0,
            size: 1,
            value: d3.timeMinute,
            label: 'Last 1 minute',
            unit: 'minutes',
        },
        {
            id: 1,
            size: 5,
            value: d3.timeMinute,
            label: 'Last 5 minutes',
            unit: 'minutes',
        },
        {
            id: 2,
            size: 15,
            value: d3.timeMinute,
            label: 'Last 15 minutes',
            unit: 'minutes',
        },
        {
            id: 3,
            size: 30,
            value: d3.timeMinute,
            label: 'Last 30 minutes',
            unit: 'minutes',
        },
        {
            id: 4,
            size: 1,
            value: d3.timeHour,
            label: 'Last 1 hour',
            unit: 'hours',
        },
        {
            id: 5,
            size: 2,
            value: d3.timeHour,
            label: 'Last 2 hours',
            unit: 'hours',
        },
        {
            id: 6,
            size: 3,
            value: d3.timeHour,
            label: 'Last 3 hours',
            unit: 'hours',
        },
        {
            id: 7,
            size: 6,
            value: d3.timeHour,
            label: 'Last 6 hours',
            unit: 'hours',
        },
        {
            id: 8,
            size: 12,
            value: d3.timeHour,
            label: 'Last 12 hours',
            unit: 'hours',
        },
        {
            id: 9,
            size: 1,
            value: d3.timeDay,
            label: 'Last 1 day',
            unit: 'days',
        },
        {
            id: 10,
            size: 3,
            value: d3.timeDay,
            label: 'Last 3 days',
            unit: 'days',
        },
        {
            id: 11,
            size: 7,
            value: d3.timeDay,
            label: 'Last 7 days',
            unit: 'days',
        },
        {
            id: 12,
            size: 14,
            value: d3.timeDay,
            label: 'Last 14 days',
            unit: 'days',
        },
        {
            id: 13,
            size: 1,
            value: d3.timeMonth,
            label: 'Last 1 month',
            unit: 'months',
        },
        {
            id: 14,
            size: 3,
            value: d3.timeMonth,
            label: 'Last 3 months',
            unit: 'months',
        },
        {
            id: 15,
            size: 6,
            value: d3.timeMonth,
            label: 'Last 6 months',
            unit: 'months',
        },
        {
            id: 16,
            size: 12,
            value: d3.timeMonth,
            label: 'Last 1 year',
            unit: 'months',
        },
    ],

    RESOLUTIONS: [
    // {
    //   id: 0,
    //   res_x: 1,
    //   res_period: 'seconds',
    //   // value: d3.timeSecond,
    //   k: 1,
    // },
    // {
    //   id: 1,
    //   res_x: 5,
    //   res_period: 'seconds',
    //   // value: d3.timeSecond.every(5),
    //   k: 1,
    // },
        {
            id: 2,
            res_x: 15,
            res_period: 'seconds',
            // value: d3.timeSecond.every(15),
            k: 1,
        },
        {
            id: 3,
            res_x: 30,
            res_period: 'seconds',
            // value: d3.timeSecond.every(30),
            k: 1,
        },
        {
            id: 4,
            res_x: 1,
            res_period: 'minutes',
            // value: d3.timeMinute,
            k: 60,
        },
        {
            id: 5,
            res_x: 5,
            res_period: 'minutes',
            // value: d3.timeMinute.every(5),
            k: 60,
        },
        {
            id: 6,
            res_x: 15,
            res_period: 'minutes',
            // value: d3.timeMinute.every(15),
            k: 60,
        },
        {
            id: 7,
            res_x: 30,
            res_period: 'minutes',
            // value: d3.timeMinute.every(30),
            k: 60,
        },
        {
            id: 8,
            res_x: 1,
            res_period: 'hours',
            // value: d3.timeHour,
            k: 3600,
        },
        {
            id: 9,
            res_x: 3,
            res_period: 'hours',
            // value: d3.timeHour.every(3),
            k: 3600,
        },
        {
            id: 10,
            res_x: 6,
            res_period: 'hours',
            // value: d3.timeHour.every(6),
            k: 3600,
        },
        {
            id: 11,
            res_x: 12,
            res_period: 'hours',
            // value: d3.timeHour.every(12),
            k: 3600,
        },
        {
            id: 12,
            res_x: 1,
            res_period: 'days',
            // value: d3.timeDay,
            k: 3600 * 24,
        },
        {
            id: 13,
            res_x: 1,
            res_period: 'weeks',
            // value: d3.timeWeek,
            k: 3600 * 24,
        },
        // {
        //   id: 14, res_x: 2, res_period: 'weeks', value: d3.timeWeek.every(2), k: 3600 * 24,
        // },
        {
            id: 15,
            res_x: 1,
            res_period: 'months',
            // value: d3.timeMonth,
            k: 3600 * 24,
        },
        {
            id: 16,
            res_x: 1,
            res_period: 'quarters',
            // value: d3.timeMonth.every(3),
            k: 3600 * 24,
        },
        // {
        //   id: 17, res_x: 6, res_period: 'months', value: d3.timeMonth.every(6), k: 3600 * 24,
        // },
        {
            id: 18,
            res_x: 1,
            res_period: 'years',
            // value: d3.timeYear,
            k: 3600 * 24,
        },
    ],

    EVENTS: {
        INITIALIZE: 'initialize',
        REFRESH: 'refresh',
        GLOBAL_RESIZE: 'global_resize',
        CLEAR_FLASH: 'clear_flash',
        STREAM: 'stream',
        STOP_STREAM: 'stop_stream',

        CANCEL_TASK: 'CANCEL_TASK',
    },

    LABELS: [
        {
            id: 0,
            field: 'sku',
        },
        {
            id: 1,
            field: 'w/o',
        },
        {
            id: 2,
            field: 's/n',
        },
    ],

    // new -- redux Labels
    LABEL_VALUES: [
        {
            id: 0,
            field: 'Issue',
        },
        {
            id: 1,
            field: 'SKU',
        },
        {
            id: 2,
            field: 'W/O',
        },
        {
            id: 3,
            field: 'S/N',
        },
    ],

    TIME_FORMAT: d3.timeFormat('%Y-%b-%d %a %X'),
    TIME_FORMAT_MOMENT: 'YYYY-MM-DD',

    DND: {
        ITEM_TYPES: {
            WIDGET: 'WIDGET',
            CANVAS: 'CANVAS',
        },
    },

    DASHBOARD: {
        CELL_WIDTH: 170,
        CELL_HEIGHT: 250,
        CELL_PADDING: 20,
        MARGIN: {
            // top: 10,
            top: 0,
            right: 10,
            bottom: 10,
            left: 10,
        },
    // TILE_DISPLAY_MODES: [
    //   { id: 1, label: 'Time-Series', value: 'TIME SERIES' },
    //   { id: 2, label: 'Summary', value: 'SUMMARY' },
    //   { id: 3, label: 'Label', value: 'LABEL' },
    // ],
    },

    BLOCKS: {
        SPEED: 'speed',
        CAPACITY: 'capacity',
        FIXED: 'fixed',
        SEQUENCE: 'seq',
        AND: 'and',
        OR: 'or',
        MODES: {
            EDIT: 'edit',
            SELECT: 'select',
        },
        EDITOR: {
            DND: {
                TYPES: {
                    CARD: 'card',
                    ITEM: 'item',
                    BIN: 'bin',
                    OPERATOR: 'operator',
                },
            },
        },
        OPERATIONS: [
            { name: 'Sequence', value: 'seq' },
            { name: 'And', value: 'and' },
            { name: 'Or', value: 'or' },
        ],
        BN_MODE: {
            speed: {
                name: '[DEFAULT] Speed - Highest Standard Time',
                value: 'speed',
                color: '#EDAE49',
                description:
          'Automatically determines bottleneck process (based on highest Standard Time)',
                show: true,
            },
            capacity: {
                name: 'Capacity - Highest OEE',
                value: 'capacity',
                color: '#05668D',
                description:
          'Automatically determines bottleneck process (based on highest OEE)',
                show: false,
            },
            fixed: {
                name: 'Fix bottleneck process',
                value: 'fixed',
                color: '#D64933',
                description: 'Manually select a bottleneck process',
                show: true,
            },
        },
        BN_MODE_DEFAULT: 'speed',
    },

    VIEWPORTS: {
        BREAKPOINTS: {
            PHONE: 575.98,
            TAB: 767.98,
            LARGE_TAB: 990.98,
            PC: 1279.98,
            DEFAULT: 990.98,
        },
        ASPECT_RATIO: {
            MAX: 2,
        },
    },

    ENTITY: {
        INDUSTRIES: [
            {
                name: 'Aerospace',
                value: 'Aerospace',
            },
            {
                name: 'Consumer Goods',
                value: 'Consumer Goods',
            },
            {
                name: 'Food & Beverage',
                value: 'Food & Beverage',
            },
            {
                name: 'Creative Industries',
                value: 'Creative Industries',
            },
            {
                name: 'Electronics',
                value: 'Electronics',
            },
            {
                name: 'Energy & Chemicals',
                value: 'Energy & Chemicals',
            },
            {
                name: 'Information & Communications Technology',
                value: 'Information & Communications Technology',
            },
            {
                name: 'Logistics & Supply Chain Management',
                value: 'Logistics & Supply Chain Management',
            },
            {
                name: 'Oil & Gas Equipment and Services',
                value: 'Oil & Gas Equipment and Services',
            },
            {
                name: 'Medical Technology',
                value: 'Medical Technology',
            },
            {
                name: 'Natural Resources',
                value: 'Natural Resources',
            },
            {
                name: 'Pharmaceuticals & Biotechnology',
                value: 'Pharmaceuticals & Biotechnology',
            },
            {
                name: 'Precision Engineering',
                value: 'Precision Engineering',
            },
            {
                name: 'Professional Services',
                value: 'Professional Services',
            },
            {
                name: 'Urban Solutions & Sustainability',
                value: 'Urban Solutions & Sustainability',
            },
        ],
    },

    COUNTRYCODES: [
        { name: 'Afghanistan', dial_code: 93, code: 'AF' },
        { name: 'Aland Islands', dial_code: 358, code: 'AX' },
        { name: 'Albania', dial_code: 355, code: 'AL' },
        { name: 'Algeria', dial_code: 213, code: 'DZ' },
        { name: 'American Samoa', dial_code: 1684, code: 'AS' },
        { name: 'Andorra', dial_code: 376, code: 'AD' },
        { name: 'Angola', dial_code: 244, code: 'AO' },
        { name: 'Anguilla', dial_code: 1264, code: 'AI' },
        { name: 'Antarctica', dial_code: 672, code: 'AQ' },
        { name: 'Antigua and Barbuda', dial_code: 1268, code: 'AG' },
        { name: 'Argentina', dial_code: 54, code: 'AR' },
        { name: 'Armenia', dial_code: 374, code: 'AM' },
        { name: 'Aruba', dial_code: 297, code: 'AW' },
        { name: 'Australia', dial_code: 61, code: 'AU' },
        { name: 'Austria', dial_code: 43, code: 'AT' },
        { name: 'Azerbaijan', dial_code: 994, code: 'AZ' },
        { name: 'Bahamas', dial_code: 1242, code: 'BS' },
        { name: 'Bahrain', dial_code: 973, code: 'BH' },
        { name: 'Bangladesh', dial_code: 880, code: 'BD' },
        { name: 'Barbados', dial_code: 1246, code: 'BB' },
        { name: 'Belarus', dial_code: 375, code: 'BY' },
        { name: 'Belgium', dial_code: 32, code: 'BE' },
        { name: 'Belize', dial_code: 501, code: 'BZ' },
        { name: 'Benin', dial_code: 229, code: 'BJ' },
        { name: 'Bermuda', dial_code: 1441, code: 'BM' },
        { name: 'Bhutan', dial_code: 975, code: 'BT' },
        {
            name: 'Bolivia, Plurinational State of bolivia',
            dial_code: 591,
            code: 'BO',
        },
        { name: 'Bosnia and Herzegovina', dial_code: 387, code: 'BA' },
        { name: 'Botswana', dial_code: 267, code: 'BW' },
        { name: 'Brazil', dial_code: 55, code: 'BR' },
        { name: 'British Indian Ocean Territory', dial_code: 246, code: 'IO' },
        { name: 'Brunei Darussalam', dial_code: 673, code: 'BN' },
        { name: 'Bulgaria', dial_code: 359, code: 'BG' },
        { name: 'Burkina Faso', dial_code: 226, code: 'BF' },
        { name: 'Burundi', dial_code: 257, code: 'BI' },
        { name: 'Cambodia', dial_code: 855, code: 'KH' },
        { name: 'Cameroon', dial_code: 237, code: 'CM' },
        { name: 'Canada', dial_code: 1, code: 'CA' },
        { name: 'Cape Verde', dial_code: 238, code: 'CV' },
        { name: 'Cayman Islands', dial_code: 345, code: 'KY' },
        { name: 'Central African Republic', dial_code: 236, code: 'CF' },
        { name: 'Chad', dial_code: 235, code: 'TD' },
        { name: 'Chile', dial_code: 56, code: 'CL' },
        { name: 'China', dial_code: 86, code: 'CN' },
        // { name: 'Christmas Island', dial_code: 61, code: 'CX' },
        // { name: 'Cocos (Keeling) Islands', dial_code: 61, code: 'CC' },
        { name: 'Colombia', dial_code: 57, code: 'CO' },
        { name: 'Comoros', dial_code: 269, code: 'KM' },
        { name: 'Congo', dial_code: 242, code: 'CG' },
        {
            name: 'Congo, The Democratic Republic of the Congo',
            dial_code: 243,
            code: 'CD',
        },
        { name: 'Cook Islands', dial_code: 682, code: 'CK' },
        { name: 'Costa Rica', dial_code: 506, code: 'CR' },
        { name: "Cote d'Ivoire", dial_code: 225, code: 'CI' },
        { name: 'Croatia', dial_code: 385, code: 'HR' },
        { name: 'Cuba', dial_code: 53, code: 'CU' },
        { name: 'Cyprus', dial_code: 357, code: 'CY' },
        { name: 'Czech Republic', dial_code: 420, code: 'CZ' },
        { name: 'Denmark', dial_code: 45, code: 'DK' },
        { name: 'Djibouti', dial_code: 253, code: 'DJ' },
        { name: 'Dominica', dial_code: 1767, code: 'DM' },
        { name: 'Dominican Republic', dial_code: 1849, code: 'DO' },
        { name: 'Ecuador', dial_code: 593, code: 'EC' },
        { name: 'Egypt', dial_code: 20, code: 'EG' },
        { name: 'El Salvador', dial_code: 503, code: 'SV' },
        { name: 'Equatorial Guinea', dial_code: 240, code: 'GQ' },
        { name: 'Eritrea', dial_code: 291, code: 'ER' },
        { name: 'Estonia', dial_code: 372, code: 'EE' },
        { name: 'Ethiopia', dial_code: 251, code: 'ET' },
        {
            name: 'Falkland Islands (Malvinas) / South Georgia and the South Sandwich Islands',
            dial_code: 500,
            code: 'FK',
        },
        { name: 'Faroe Islands', dial_code: 298, code: 'FO' },
        { name: 'Fiji', dial_code: 679, code: 'FJ' },
        { name: 'Finland', dial_code: 358, code: 'FI' },
        { name: 'France', dial_code: 33, code: 'FR' },
        { name: 'French Guiana', dial_code: 594, code: 'GF' },
        { name: 'French Polynesia', dial_code: 689, code: 'PF' },
        { name: 'Gabon', dial_code: 241, code: 'GA' },
        { name: 'Gambia', dial_code: 220, code: 'GM' },
        { name: 'Georgia', dial_code: 995, code: 'GE' },
        { name: 'Germany', dial_code: 49, code: 'DE' },
        { name: 'Ghana', dial_code: 233, code: 'GH' },
        { name: 'Gibraltar', dial_code: 350, code: 'GI' },
        { name: 'Greece', dial_code: 30, code: 'GR' },
        { name: 'Greenland', dial_code: 299, code: 'GL' },
        { name: 'Grenada', dial_code: 1473, code: 'GD' },
        {
            name: 'Guadeloupe / Saint Barthelemy / Saint Martin',
            dial_code: 590,
            code: 'GP',
        },
        { name: 'Guam', dial_code: 1671, code: 'GU' },
        { name: 'Guatemala', dial_code: 502, code: 'GT' },
        // { name: 'Guernsey', dial_code: 44, code: 'GG' },
        { name: 'Guinea', dial_code: 224, code: 'GN' },
        { name: 'Guinea-Bissau', dial_code: 245, code: 'GW' },
        { name: 'Guyana', dial_code: 595, code: 'GY' },
        { name: 'Haiti', dial_code: 509, code: 'HT' },
        { name: 'Holy See (Vatican City State)', dial_code: 379, code: 'VA' },
        { name: 'Honduras', dial_code: 504, code: 'HN' },
        { name: 'Hong Kong', dial_code: 852, code: 'HK' },
        { name: 'Hungary', dial_code: 36, code: 'HU' },
        { name: 'Iceland', dial_code: 354, code: 'IS' },
        { name: 'India', dial_code: 91, code: 'IN' },
        { name: 'Indonesia', dial_code: 62, code: 'ID' },
        {
            name: 'Iran, Islamic Republic of Persian Gulf',
            dial_code: 98,
            code: 'IR',
        },
        { name: 'Iraq', dial_code: 964, code: 'IQ' },
        { name: 'Ireland', dial_code: 353, code: 'IE' },
        // { name: 'Isle of Man', dial_code: 44, code: 'IM' },
        { name: 'Israel', dial_code: 972, code: 'IL' },
        { name: 'Italy', dial_code: 39, code: 'IT' },
        { name: 'Jamaica', dial_code: 1876, code: 'JM' },
        { name: 'Japan', dial_code: 81, code: 'JP' },
        // { name: 'Jersey', dial_code: 44, code: 'JE' },
        { name: 'Jordan', dial_code: 962, code: 'JO' },
        { name: 'Kazakhstan', dial_code: 77, code: 'KZ' },
        { name: 'Kenya', dial_code: 254, code: 'KE' },
        { name: 'Kiribati', dial_code: 686, code: 'KI' },
        {
            name: "Korea, Democratic People's Republic of Korea",
            dial_code: 850,
            code: 'KP',
        },
        { name: 'Korea, Republic of South Korea', dial_code: 82, code: 'KR' },
        { name: 'Kuwait', dial_code: 965, code: 'KW' },
        { name: 'Kyrgyzstan', dial_code: 996, code: 'KG' },
        { name: 'Laos', dial_code: 856, code: 'LA' },
        { name: 'Latvia', dial_code: 371, code: 'LV' },
        { name: 'Lebanon', dial_code: 961, code: 'LB' },
        { name: 'Lesotho', dial_code: 266, code: 'LS' },
        { name: 'Liberia', dial_code: 231, code: 'LR' },
        { name: 'Libyan Arab Jamahiriya', dial_code: 218, code: 'LY' },
        { name: 'Liechtenstein', dial_code: 423, code: 'LI' },
        { name: 'Lithuania', dial_code: 370, code: 'LT' },
        { name: 'Luxembourg', dial_code: 352, code: 'LU' },
        { name: 'Macao', dial_code: 853, code: 'MO' },
        { name: 'Macedonia', dial_code: 389, code: 'MK' },
        { name: 'Madagascar', dial_code: 261, code: 'MG' },
        { name: 'Malawi', dial_code: 265, code: 'MW' },
        { name: 'Malaysia', dial_code: 60, code: 'MY' },
        { name: 'Maldives', dial_code: 960, code: 'MV' },
        { name: 'Mali', dial_code: 223, code: 'ML' },
        { name: 'Malta', dial_code: 356, code: 'MT' },
        { name: 'Marshall Islands', dial_code: 692, code: 'MH' },
        { name: 'Martinique', dial_code: 596, code: 'MQ' },
        { name: 'Mauritania', dial_code: 222, code: 'MR' },
        { name: 'Mauritius', dial_code: 230, code: 'MU' },
        { name: 'Mayotte', dial_code: 262, code: 'YT' },
        { name: 'Mexico', dial_code: 52, code: 'MX' },
        {
            name: 'Micronesia, Federated States of Micronesia',
            dial_code: 691,
            code: 'FM',
        },
        { name: 'Moldova', dial_code: 373, code: 'MD' },
        { name: 'Monaco', dial_code: 377, code: 'MC' },
        { name: 'Mongolia', dial_code: 976, code: 'MN' },
        { name: 'Montenegro', dial_code: 382, code: 'ME' },
        { name: 'Montserrat', dial_code: 1664, code: 'MS' },
        { name: 'Morocco', dial_code: 212, code: 'MA' },
        { name: 'Mozambique', dial_code: 258, code: 'MZ' },
        { name: 'Myanmar', dial_code: 95, code: 'MM' },
        { name: 'Namibia', dial_code: 264, code: 'NA' },
        { name: 'Nauru', dial_code: 674, code: 'NR' },
        { name: 'Nepal', dial_code: 977, code: 'NP' },
        { name: 'Netherlands', dial_code: 31, code: 'NL' },
        { name: 'Netherlands Antilles', dial_code: 599, code: 'AN' },
        { name: 'New Caledonia', dial_code: 687, code: 'NC' },
        { name: 'New Zealand', dial_code: 64, code: 'NZ' },
        { name: 'Nicaragua', dial_code: 505, code: 'NI' },
        { name: 'Niger', dial_code: 227, code: 'NE' },
        { name: 'Nigeria', dial_code: 234, code: 'NG' },
        { name: 'Niue', dial_code: 683, code: 'NU' },
        { name: 'Norfolk Island', dial_code: 672, code: 'NF' },
        { name: 'Northern Mariana Islands', dial_code: 1670, code: 'MP' },
        { name: 'Norway', dial_code: 47, code: 'NO' },
        { name: 'Oman', dial_code: 968, code: 'OM' },
        { name: 'Pakistan', dial_code: 92, code: 'PK' },
        { name: 'Palau', dial_code: 680, code: 'PW' },
        { name: 'Palestinian Territory, Occupied', dial_code: 970, code: 'PS' },
        { name: 'Panama', dial_code: 507, code: 'PA' },
        { name: 'Papua New Guinea', dial_code: 675, code: 'PG' },
        { name: 'Paraguay', dial_code: 595, code: 'PY' },
        { name: 'Peru', dial_code: 51, code: 'PE' },
        { name: 'Philippines', dial_code: 63, code: 'PH' },
        { name: 'Pitcairn', dial_code: 872, code: 'PN' },
        { name: 'Poland', dial_code: 48, code: 'PL' },
        { name: 'Portugal', dial_code: 351, code: 'PT' },
        { name: 'Puerto Rico', dial_code: 1939, code: 'PR' },
        { name: 'Qatar', dial_code: 974, code: 'QA' },
        { name: 'Romania', dial_code: 40, code: 'RO' },
        { name: 'Russia', dial_code: 7, code: 'RU' },
        { name: 'Rwanda', dial_code: 250, code: 'RW' },
        { name: 'Reunion', dial_code: 262, code: 'RE' },
        // { name: 'Saint Barthelemy', dial_code: 590, code: 'BL' },
        {
            name: 'Saint Helena, Ascension and Tristan Da Cunha',
            dial_code: 290,
            code: 'SH',
        },
        { name: 'Saint Kitts and Nevis', dial_code: 1869, code: 'KN' },
        { name: 'Saint Lucia', dial_code: 1758, code: 'LC' },
        // { name: 'Saint Martin', dial_code: 590, code: 'MF' },
        { name: 'Saint Pierre and Miquelon', dial_code: 508, code: 'PM' },
        { name: 'Saint Vincent and the Grenadines', dial_code: 1784, code: 'VC' },
        { name: 'Samoa', dial_code: 685, code: 'WS' },
        { name: 'San Marino', dial_code: 378, code: 'SM' },
        { name: 'Sao Tome and Principe', dial_code: 239, code: 'ST' },
        { name: 'Saudi Arabia', dial_code: 966, code: 'SA' },
        { name: 'Senegal', dial_code: 221, code: 'SN' },
        { name: 'Serbia', dial_code: 381, code: 'RS' },
        { name: 'Seychelles', dial_code: 248, code: 'SC' },
        { name: 'Sierra Leone', dial_code: 232, code: 'SL' },
        { name: 'Singapore', dial_code: 65, code: 'SG' },
        { name: 'Slovakia', dial_code: 421, code: 'SK' },
        { name: 'Slovenia', dial_code: 386, code: 'SI' },
        { name: 'Solomon Islands', dial_code: 677, code: 'SB' },
        { name: 'Somalia', dial_code: 252, code: 'SO' },
        { name: 'South Africa', dial_code: 27, code: 'ZA' },
        { name: 'South Sudan', dial_code: 211, code: 'SS' },
        // {
        //   name: 'South Georgia and the South Sandwich Islands',
        //   dial_code: 500,
        //   code: 'GS',
        // },
        { name: 'Spain', dial_code: 34, code: 'ES' },
        { name: 'Sri Lanka', dial_code: 94, code: 'LK' },
        { name: 'Sudan', dial_code: 249, code: 'SD' },
        { name: 'Suriname', dial_code: 597, code: 'SR' },
        { name: 'Svalbard and Jan Mayen', dial_code: 47, code: 'SJ' },
        { name: 'Swaziland', dial_code: 268, code: 'SZ' },
        { name: 'Sweden', dial_code: 46, code: 'SE' },
        { name: 'Switzerland', dial_code: 41, code: 'CH' },
        { name: 'Syrian Arab Republic', dial_code: 963, code: 'SY' },
        { name: 'Taiwan', dial_code: 886, code: 'TW' },
        { name: 'Tajikistan', dial_code: 992, code: 'TJ' },
        {
            name: 'Tanzania, United Republic of Tanzania',
            dial_code: 255,
            code: 'TZ',
        },
        { name: 'Thailand', dial_code: 66, code: 'TH' },
        { name: 'Timor-Leste', dial_code: 670, code: 'TL' },
        { name: 'Togo', dial_code: 228, code: 'TG' },
        { name: 'Tokelau', dial_code: 690, code: 'TK' },
        { name: 'Tonga', dial_code: 676, code: 'TO' },
        { name: 'Trinidad and Tobago', dial_code: 1868, code: 'TT' },
        { name: 'Tunisia', dial_code: 216, code: 'TN' },
        { name: 'Turkey', dial_code: 90, code: 'TR' },
        { name: 'Turkmenistan', dial_code: 993, code: 'TM' },
        { name: 'Turks and Caicos Islands', dial_code: 1649, code: 'TC' },
        { name: 'Tuvalu', dial_code: 688, code: 'TV' },
        { name: 'Uganda', dial_code: 256, code: 'UG' },
        { name: 'Ukraine', dial_code: 380, code: 'UA' },
        { name: 'United Arab Emirates', dial_code: 971, code: 'AE' },
        { name: 'United Kingdom', dial_code: 44, code: 'GB' },
        { name: 'United States', dial_code: 1, code: 'US' },
        { name: 'Uruguay', dial_code: 598, code: 'UY' },
        { name: 'Uzbekistan', dial_code: 998, code: 'UZ' },
        { name: 'Vanuatu', dial_code: 678, code: 'VU' },
        {
            name: 'Venezuela, Bolivarian Republic of Venezuela',
            dial_code: 58,
            code: 'VE',
        },
        { name: 'Vietnam', dial_code: 84, code: 'VN' },
        { name: 'Virgin Islands, British', dial_code: 1284, code: 'VG' },
        { name: 'Virgin Islands, U.S.', dial_code: 1340, code: 'VI' },
        { name: 'Wallis and Futuna', dial_code: 681, code: 'WF' },
        { name: 'Yemen', dial_code: 967, code: 'YE' },
        { name: 'Zambia', dial_code: 260, code: 'ZM' },
        { name: 'Zimbabwe', dial_code: 263, code: 'ZW' },
    ],

    TIMEZONES: [
    // https://github.com/filipdanic/compact-timezone-list/blob/master/index.js
        { offset: '-11:00', label: '(GMT-11:00) Niue', tzCode: 'Pacific/Niue' },
        {
            offset: '-11:00',
            label: '(GMT-11:00) Pago Pago',
            tzCode: 'Pacific/Pago_Pago',
        },
        {
            offset: '-10:00',
            label: '(GMT-10:00) Hawaii Time',
            tzCode: 'Pacific/Honolulu',
        },
        {
            offset: '-10:00',
            label: '(GMT-10:00) Rarotonga',
            tzCode: 'Pacific/Rarotonga',
        },
        { offset: '-10:00', label: '(GMT-10:00) Tahiti', tzCode: 'Pacific/Tahiti' },
        {
            offset: '-09:30',
            label: '(GMT-09:30) Marquesas',
            tzCode: 'Pacific/Marquesas',
        },
        {
            offset: '-09:00',
            label: '(GMT-09:00) Alaska Time',
            tzCode: 'America/Anchorage',
        },
        {
            offset: '-09:00',
            label: '(GMT-09:00) Gambier',
            tzCode: 'Pacific/Gambier',
        },
        {
            offset: '-08:00',
            label: '(GMT-08:00) Pacific Time',
            tzCode: 'America/Los_Angeles',
        },
        {
            offset: '-08:00',
            label: '(GMT-08:00) Pacific Time - Tijuana',
            tzCode: 'America/Tijuana',
        },
        {
            offset: '-08:00',
            label: '(GMT-08:00) Pacific Time - Vancouver',
            tzCode: 'America/Vancouver',
        },
        {
            offset: '-08:00',
            label: '(GMT-08:00) Pacific Time - Whitehorse',
            tzCode: 'America/Whitehorse',
        },
        {
            offset: '-08:00',
            label: '(GMT-08:00) Pitcairn',
            tzCode: 'Pacific/Pitcairn',
        },
        {
            offset: '-07:00',
            label: '(GMT-07:00) Mountain Time',
            tzCode: 'America/Denver',
        },
        {
            offset: '-07:00',
            label: '(GMT-07:00) Mountain Time - Arizona',
            tzCode: 'America/Phoenix',
        },
        {
            offset: '-07:00',
            label: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
            tzCode: 'America/Mazatlan',
        },
        {
            offset: '-07:00',
            label: '(GMT-07:00) Mountain Time - Dawson Creek',
            tzCode: 'America/Dawson_Creek',
        },
        {
            offset: '-07:00',
            label: '(GMT-07:00) Mountain Time - Edmonton',
            tzCode: 'America/Edmonton',
        },
        {
            offset: '-07:00',
            label: '(GMT-07:00) Mountain Time - Hermosillo',
            tzCode: 'America/Hermosillo',
        },
        {
            offset: '-07:00',
            label: '(GMT-07:00) Mountain Time - Yellowknife',
            tzCode: 'America/Yellowknife',
        },
        { offset: '-06:00', label: '(GMT-06:00) Belize', tzCode: 'America/Belize' },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Central Time',
            tzCode: 'America/Chicago',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Central Time - Mexico City',
            tzCode: 'America/Mexico_City',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Central Time - Regina',
            tzCode: 'America/Regina',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Central Time - Tegucigalpa',
            tzCode: 'America/Tegucigalpa',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Central Time - Winnipeg',
            tzCode: 'America/Winnipeg',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Costa Rica',
            tzCode: 'America/Costa_Rica',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) El Salvador',
            tzCode: 'America/El_Salvador',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Galapagos',
            tzCode: 'Pacific/Galapagos',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Guatemala',
            tzCode: 'America/Guatemala',
        },
        {
            offset: '-06:00',
            label: '(GMT-06:00) Managua',
            tzCode: 'America/Managua',
        },
        {
            offset: '-05:00',
            label: '(GMT-05:00) America Cancun',
            tzCode: 'America/Cancun',
        },
        { offset: '-05:00', label: '(GMT-05:00) Bogota', tzCode: 'America/Bogota' },
        {
            offset: '-05:00',
            label: '(GMT-05:00) Easter Island',
            tzCode: 'Pacific/Easter',
        },
        {
            offset: '-05:00',
            label: '(GMT-05:00) Eastern Time',
            tzCode: 'America/New_York',
        },
        {
            offset: '-05:00',
            label: '(GMT-05:00) Eastern Time - Iqaluit',
            tzCode: 'America/Iqaluit',
        },
        {
            offset: '-05:00',
            label: '(GMT-05:00) Eastern Time - Toronto',
            tzCode: 'America/Toronto',
        },
        {
            offset: '-05:00',
            label: '(GMT-05:00) Guayaquil',
            tzCode: 'America/Guayaquil',
        },
        { offset: '-05:00', label: '(GMT-05:00) Havana', tzCode: 'America/Havana' },
        {
            offset: '-05:00',
            label: '(GMT-05:00) Jamaica',
            tzCode: 'America/Jamaica',
        },
        { offset: '-05:00', label: '(GMT-05:00) Lima', tzCode: 'America/Lima' },
        { offset: '-05:00', label: '(GMT-05:00) Nassau', tzCode: 'America/Nassau' },
        { offset: '-05:00', label: '(GMT-05:00) Panama', tzCode: 'America/Panama' },
        {
            offset: '-05:00',
            label: '(GMT-05:00) Port-au-Prince',
            tzCode: 'America/Port-au-Prince',
        },
        {
            offset: '-05:00',
            label: '(GMT-05:00) Rio Branco',
            tzCode: 'America/Rio_Branco',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Atlantic Time - Halifax',
            tzCode: 'America/Halifax',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Barbados',
            tzCode: 'America/Barbados',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Bermuda',
            tzCode: 'Atlantic/Bermuda',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Boa Vista',
            tzCode: 'America/Boa_Vista',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Caracas',
            tzCode: 'America/Caracas',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Curacao',
            tzCode: 'America/Curacao',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Grand Turk',
            tzCode: 'America/Grand_Turk',
        },
        { offset: '-04:00', label: '(GMT-04:00) Guyana', tzCode: 'America/Guyana' },
        { offset: '-04:00', label: '(GMT-04:00) La Paz', tzCode: 'America/La_Paz' },
        { offset: '-04:00', label: '(GMT-04:00) Manaus', tzCode: 'America/Manaus' },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Martinique',
            tzCode: 'America/Martinique',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Port of Spain',
            tzCode: 'America/Port_of_Spain',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Porto Velho',
            tzCode: 'America/Porto_Velho',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Puerto Rico',
            tzCode: 'America/Puerto_Rico',
        },
        {
            offset: '-04:00',
            label: '(GMT-04:00) Santo Domingo',
            tzCode: 'America/Santo_Domingo',
        },
        { offset: '-04:00', label: '(GMT-04:00) Thule', tzCode: 'America/Thule' },
        {
            offset: '-03:30',
            label: '(GMT-03:30) Newfoundland Time - St. Johns',
            tzCode: 'America/St_Johns',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Araguaina',
            tzCode: 'America/Araguaina',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Asuncion',
            tzCode: 'America/Asuncion',
        },
        { offset: '-03:00', label: '(GMT-03:00) Belem', tzCode: 'America/Belem' },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Buenos Aires',
            tzCode: 'America/Argentina/Buenos_Aires',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Campo Grande',
            tzCode: 'America/Campo_Grande',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Cayenne',
            tzCode: 'America/Cayenne',
        },
        { offset: '-03:00', label: '(GMT-03:00) Cuiaba', tzCode: 'America/Cuiaba' },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Fortaleza',
            tzCode: 'America/Fortaleza',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Godthab',
            tzCode: 'America/Godthab',
        },
        { offset: '-03:00', label: '(GMT-03:00) Maceio', tzCode: 'America/Maceio' },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Miquelon',
            tzCode: 'America/Miquelon',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Montevideo',
            tzCode: 'America/Montevideo',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Palmer',
            tzCode: 'Antarctica/Palmer',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Paramaribo',
            tzCode: 'America/Paramaribo',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Punta Arenas',
            tzCode: 'America/Punta_Arenas',
        },
        { offset: '-03:00', label: '(GMT-03:00) Recife', tzCode: 'America/Recife' },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Rothera',
            tzCode: 'Antarctica/Rothera',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Salvador',
            tzCode: 'America/Bahia',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Santiago',
            tzCode: 'America/Santiago',
        },
        {
            offset: '-03:00',
            label: '(GMT-03:00) Stanley',
            tzCode: 'Atlantic/Stanley',
        },
        {
            offset: '-02:00',
            label: '(GMT-02:00) Noronha',
            tzCode: 'America/Noronha',
        },
        {
            offset: '-02:00',
            label: '(GMT-02:00) Sao Paulo',
            tzCode: 'America/Sao_Paulo',
        },
        {
            offset: '-02:00',
            label: '(GMT-02:00) South Georgia',
            tzCode: 'Atlantic/South_Georgia',
        },
        {
            offset: '-01:00',
            label: '(GMT-01:00) Azores',
            tzCode: 'Atlantic/Azores',
        },
        {
            offset: '-01:00',
            label: '(GMT-01:00) Cape Verde',
            tzCode: 'Atlantic/Cape_Verde',
        },
        {
            offset: '-01:00',
            label: '(GMT-01:00) Scoresbysund',
            tzCode: 'America/Scoresbysund',
        },
        {
            offset: '+00:00',
            label: '(GMT+00:00) Abidjan',
            tzCode: 'Africa/Abidjan',
        },
        { offset: '+00:00', label: '(GMT+00:00) Accra', tzCode: 'Africa/Accra' },
        { offset: '+00:00', label: '(GMT+00:00) Bissau', tzCode: 'Africa/Bissau' },
        {
            offset: '+00:00',
            label: '(GMT+00:00) Canary Islands',
            tzCode: 'Atlantic/Canary',
        },
        {
            offset: '+00:00',
            label: '(GMT+00:00) Casablanca',
            tzCode: 'Africa/Casablanca',
        },
        {
            offset: '+00:00',
            label: '(GMT+00:00) Danmarkshavn',
            tzCode: 'America/Danmarkshavn',
        },
        { offset: '+00:00', label: '(GMT+00:00) Dublin', tzCode: 'Europe/Dublin' },
        {
            offset: '+00:00',
            label: '(GMT+00:00) El Aaiun',
            tzCode: 'Africa/El_Aaiun',
        },
        { offset: '+00:00', label: '(GMT+00:00) Faeroe', tzCode: 'Atlantic/Faroe' },
        {
            offset: '+00:00',
            label: '(GMT+00:00) GMT (no daylight saving)',
            tzCode: 'Etc/GMT',
        },
        { offset: '+00:00', label: '(GMT+00:00) Lisbon', tzCode: 'Europe/Lisbon' },
        { offset: '+00:00', label: '(GMT+00:00) London', tzCode: 'Europe/London' },
        {
            offset: '+00:00',
            label: '(GMT+00:00) Monrovia',
            tzCode: 'Africa/Monrovia',
        },
        {
            offset: '+00:00',
            label: '(GMT+00:00) Reykjavik',
            tzCode: 'Atlantic/Reykjavik',
        },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Algiers',
            tzCode: 'Africa/Algiers',
        },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Amsterdam',
            tzCode: 'Europe/Amsterdam',
        },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Andorra',
            tzCode: 'Europe/Andorra',
        },
        { offset: '+01:00', label: '(GMT+01:00) Berlin', tzCode: 'Europe/Berlin' },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Brussels',
            tzCode: 'Europe/Brussels',
        },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Budapest',
            tzCode: 'Europe/Budapest',
        },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Central European Time - Belgrade',
            tzCode: 'Europe/Belgrade',
        },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Central European Time - Prague',
            tzCode: 'Europe/Prague',
        },
        { offset: '+01:00', label: '(GMT+01:00) Ceuta', tzCode: 'Africa/Ceuta' },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Copenhagen',
            tzCode: 'Europe/Copenhagen',
        },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Gibraltar',
            tzCode: 'Europe/Gibraltar',
        },
        { offset: '+01:00', label: '(GMT+01:00) Lagos', tzCode: 'Africa/Lagos' },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Luxembourg',
            tzCode: 'Europe/Luxembourg',
        },
        { offset: '+01:00', label: '(GMT+01:00) Madrid', tzCode: 'Europe/Madrid' },
        { offset: '+01:00', label: '(GMT+01:00) Malta', tzCode: 'Europe/Malta' },
        { offset: '+01:00', label: '(GMT+01:00) Monaco', tzCode: 'Europe/Monaco' },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Ndjamena',
            tzCode: 'Africa/Ndjamena',
        },
        { offset: '+01:00', label: '(GMT+01:00) Oslo', tzCode: 'Europe/Oslo' },
        { offset: '+01:00', label: '(GMT+01:00) Paris', tzCode: 'Europe/Paris' },
        { offset: '+01:00', label: '(GMT+01:00) Rome', tzCode: 'Europe/Rome' },
        {
            offset: '+01:00',
            label: '(GMT+01:00) Stockholm',
            tzCode: 'Europe/Stockholm',
        },
        { offset: '+01:00', label: '(GMT+01:00) Tirane', tzCode: 'Europe/Tirane' },
        { offset: '+01:00', label: '(GMT+01:00) Tunis', tzCode: 'Africa/Tunis' },
        { offset: '+01:00', label: '(GMT+01:00) Vienna', tzCode: 'Europe/Vienna' },
        { offset: '+01:00', label: '(GMT+01:00) Warsaw', tzCode: 'Europe/Warsaw' },
        { offset: '+01:00', label: '(GMT+01:00) Zurich', tzCode: 'Europe/Zurich' },
        { offset: '+02:00', label: '(GMT+02:00) Amman', tzCode: 'Asia/Amman' },
        { offset: '+02:00', label: '(GMT+02:00) Athens', tzCode: 'Europe/Athens' },
        { offset: '+02:00', label: '(GMT+02:00) Beirut', tzCode: 'Asia/Beirut' },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Bucharest',
            tzCode: 'Europe/Bucharest',
        },
        { offset: '+02:00', label: '(GMT+02:00) Cairo', tzCode: 'Africa/Cairo' },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Chisinau',
            tzCode: 'Europe/Chisinau',
        },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Damascus',
            tzCode: 'Asia/Damascus',
        },
        { offset: '+02:00', label: '(GMT+02:00) Gaza', tzCode: 'Asia/Gaza' },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Helsinki',
            tzCode: 'Europe/Helsinki',
        },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Jerusalem',
            tzCode: 'Asia/Jerusalem',
        },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Johannesburg',
            tzCode: 'Africa/Johannesburg',
        },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Khartoum',
            tzCode: 'Africa/Khartoum',
        },
        { offset: '+02:00', label: '(GMT+02:00) Kiev', tzCode: 'Europe/Kiev' },
        { offset: '+02:00', label: '(GMT+02:00) Maputo', tzCode: 'Africa/Maputo' },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Moscow-01 - Kaliningrad',
            tzCode: 'Europe/Kaliningrad',
        },
        { offset: '+02:00', label: '(GMT+02:00) Nicosia', tzCode: 'Asia/Nicosia' },
        { offset: '+02:00', label: '(GMT+02:00) Riga', tzCode: 'Europe/Riga' },
        { offset: '+02:00', label: '(GMT+02:00) Sofia', tzCode: 'Europe/Sofia' },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Tallinn',
            tzCode: 'Europe/Tallinn',
        },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Tripoli',
            tzCode: 'Africa/Tripoli',
        },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Vilnius',
            tzCode: 'Europe/Vilnius',
        },
        {
            offset: '+02:00',
            label: '(GMT+02:00) Windhoek',
            tzCode: 'Africa/Windhoek',
        },
        { offset: '+03:00', label: '(GMT+03:00) Baghdad', tzCode: 'Asia/Baghdad' },
        {
            offset: '+03:00',
            label: '(GMT+03:00) Istanbul',
            tzCode: 'Europe/Istanbul',
        },
        { offset: '+03:00', label: '(GMT+03:00) Minsk', tzCode: 'Europe/Minsk' },
        {
            offset: '+03:00',
            label: '(GMT+03:00) Moscow+00 - Moscow',
            tzCode: 'Europe/Moscow',
        },
        {
            offset: '+03:00',
            label: '(GMT+03:00) Nairobi',
            tzCode: 'Africa/Nairobi',
        },
        { offset: '+03:00', label: '(GMT+03:00) Qatar', tzCode: 'Asia/Qatar' },
        { offset: '+03:00', label: '(GMT+03:00) Riyadh', tzCode: 'Asia/Riyadh' },
        {
            offset: '+03:00',
            label: '(GMT+03:00) Syowa',
            tzCode: 'Antarctica/Syowa',
        },
        { offset: '+03:30', label: '(GMT+03:30) Tehran', tzCode: 'Asia/Tehran' },
        { offset: '+04:00', label: '(GMT+04:00) Baku', tzCode: 'Asia/Baku' },
        { offset: '+04:00', label: '(GMT+04:00) Dubai', tzCode: 'Asia/Dubai' },
        { offset: '+04:00', label: '(GMT+04:00) Mahe', tzCode: 'Indian/Mahe' },
        {
            offset: '+04:00',
            label: '(GMT+04:00) Mauritius',
            tzCode: 'Indian/Mauritius',
        },
        {
            offset: '+04:00',
            label: '(GMT+04:00) Moscow+01 - Samara',
            tzCode: 'Europe/Samara',
        },
        {
            offset: '+04:00',
            label: '(GMT+04:00) Reunion',
            tzCode: 'Indian/Reunion',
        },
        { offset: '+04:00', label: '(GMT+04:00) Tbilisi', tzCode: 'Asia/Tbilisi' },
        { offset: '+04:00', label: '(GMT+04:00) Yerevan', tzCode: 'Asia/Yerevan' },
        { offset: '+04:30', label: '(GMT+04:30) Kabul', tzCode: 'Asia/Kabul' },
        { offset: '+05:00', label: '(GMT+05:00) Aqtau', tzCode: 'Asia/Aqtau' },
        { offset: '+05:00', label: '(GMT+05:00) Aqtobe', tzCode: 'Asia/Aqtobe' },
        {
            offset: '+05:00',
            label: '(GMT+05:00) Ashgabat',
            tzCode: 'Asia/Ashgabat',
        },
        {
            offset: '+05:00',
            label: '(GMT+05:00) Dushanbe',
            tzCode: 'Asia/Dushanbe',
        },
        { offset: '+05:00', label: '(GMT+05:00) Karachi', tzCode: 'Asia/Karachi' },
        {
            offset: '+05:00',
            label: '(GMT+05:00) Kerguelen',
            tzCode: 'Indian/Kerguelen',
        },
        {
            offset: '+05:00',
            label: '(GMT+05:00) Maldives',
            tzCode: 'Indian/Maldives',
        },
        {
            offset: '+05:00',
            label: '(GMT+05:00) Mawson',
            tzCode: 'Antarctica/Mawson',
        },
        {
            offset: '+05:00',
            label: '(GMT+05:00) Moscow+02 - Yekaterinburg',
            tzCode: 'Asia/Yekaterinburg',
        },
        {
            offset: '+05:00',
            label: '(GMT+05:00) Tashkent',
            tzCode: 'Asia/Tashkent',
        },
        { offset: '+05:30', label: '(GMT+05:30) Colombo', tzCode: 'Asia/Colombo' },
        {
            offset: '+05:30',
            label: '(GMT+05:30) India Standard Time',
            tzCode: 'Asia/Kolkata',
        },
        {
            offset: '+05:45',
            label: '(GMT+05:45) Kathmandu',
            tzCode: 'Asia/Kathmandu',
        },
        { offset: '+06:00', label: '(GMT+06:00) Almaty', tzCode: 'Asia/Almaty' },
        { offset: '+06:00', label: '(GMT+06:00) Bishkek', tzCode: 'Asia/Bishkek' },
        { offset: '+06:00', label: '(GMT+06:00) Chagos', tzCode: 'Indian/Chagos' },
        { offset: '+06:00', label: '(GMT+06:00) Dhaka', tzCode: 'Asia/Dhaka' },
        {
            offset: '+06:00',
            label: '(GMT+06:00) Moscow+03 - Omsk',
            tzCode: 'Asia/Omsk',
        },
        { offset: '+06:00', label: '(GMT+06:00) Thimphu', tzCode: 'Asia/Thimphu' },
        {
            offset: '+06:00',
            label: '(GMT+06:00) Vostok',
            tzCode: 'Antarctica/Vostok',
        },
        { offset: '+06:30', label: '(GMT+06:30) Cocos', tzCode: 'Indian/Cocos' },
        { offset: '+06:30', label: '(GMT+06:30) Rangoon', tzCode: 'Asia/Yangon' },
        { offset: '+07:00', label: '(GMT+07:00) Bangkok', tzCode: 'Asia/Bangkok' },
        {
            offset: '+07:00',
            label: '(GMT+07:00) Christmas',
            tzCode: 'Indian/Christmas',
        },
        {
            offset: '+07:00',
            label: '(GMT+07:00) Davis',
            tzCode: 'Antarctica/Davis',
        },
        { offset: '+07:00', label: '(GMT+07:00) Hanoi', tzCode: 'Asia/Saigon' },
        { offset: '+07:00', label: '(GMT+07:00) Hovd', tzCode: 'Asia/Hovd' },
        { offset: '+07:00', label: '(GMT+07:00) Jakarta', tzCode: 'Asia/Jakarta' },
        {
            offset: '+07:00',
            label: '(GMT+07:00) Moscow+04 - Krasnoyarsk',
            tzCode: 'Asia/Krasnoyarsk',
        },
        { offset: '+08:00', label: '(GMT+08:00) Brunei', tzCode: 'Asia/Brunei' },
        {
            offset: '+08:00',
            label: '(GMT+08:00) China Time - Beijing',
            tzCode: 'Asia/Shanghai',
        },
        {
            offset: '+08:00',
            label: '(GMT+08:00) Choibalsan',
            tzCode: 'Asia/Choibalsan',
        },
        {
            offset: '+08:00',
            label: '(GMT+08:00) Hong Kong',
            tzCode: 'Asia/Hong_Kong',
        },
        {
            offset: '+08:00',
            label: '(GMT+08:00) Kuala Lumpur',
            tzCode: 'Asia/Kuala_Lumpur',
        },
        { offset: '+08:00', label: '(GMT+08:00) Macau', tzCode: 'Asia/Macau' },
        {
            offset: '+08:00',
            label: '(GMT+08:00) Makassar',
            tzCode: 'Asia/Makassar',
        },
        { offset: '+08:00', label: '(GMT+08:00) Manila', tzCode: 'Asia/Manila' },
        {
            offset: '+08:00',
            label: '(GMT+08:00) Moscow+05 - Irkutsk',
            tzCode: 'Asia/Irkutsk',
        },
        {
            offset: '+08:00',
            label: '(GMT+08:00) Singapore',
            tzCode: 'Asia/Singapore',
        },
        { offset: '+08:00', label: '(GMT+08:00) Taipei', tzCode: 'Asia/Taipei' },
        {
            offset: '+08:00',
            label: '(GMT+08:00) Ulaanbaatar',
            tzCode: 'Asia/Ulaanbaatar',
        },
        {
            offset: '+08:00',
            label: '(GMT+08:00) Western Time - Perth',
            tzCode: 'Australia/Perth',
        },
        {
            offset: '+08:30',
            label: '(GMT+08:30) Pyongyang',
            tzCode: 'Asia/Pyongyang',
        },
        { offset: '+09:00', label: '(GMT+09:00) Dili', tzCode: 'Asia/Dili' },
        {
            offset: '+09:00',
            label: '(GMT+09:00) Jayapura',
            tzCode: 'Asia/Jayapura',
        },
        {
            offset: '+09:00',
            label: '(GMT+09:00) Moscow+06 - Yakutsk',
            tzCode: 'Asia/Yakutsk',
        },
        { offset: '+09:00', label: '(GMT+09:00) Palau', tzCode: 'Pacific/Palau' },
        { offset: '+09:00', label: '(GMT+09:00) Seoul', tzCode: 'Asia/Seoul' },
        { offset: '+09:00', label: '(GMT+09:00) Tokyo', tzCode: 'Asia/Tokyo' },
        {
            offset: '+09:30',
            label: '(GMT+09:30) Central Time - Darwin',
            tzCode: 'Australia/Darwin',
        },
        {
            offset: '+10:00',
            label: "(GMT+10:00) Dumont D'Urville",
            tzCode: 'Antarctica/DumontDUrville',
        },
        {
            offset: '+10:00',
            label: '(GMT+10:00) Eastern Time - Brisbane',
            tzCode: 'Australia/Brisbane',
        },
        { offset: '+10:00', label: '(GMT+10:00) Guam', tzCode: 'Pacific/Guam' },
        {
            offset: '+10:00',
            label: '(GMT+10:00) Moscow+07 - Vladivostok',
            tzCode: 'Asia/Vladivostok',
        },
        {
            offset: '+10:00',
            label: '(GMT+10:00) Port Moresby',
            tzCode: 'Pacific/Port_Moresby',
        },
        { offset: '+10:00', label: '(GMT+10:00) Truk', tzCode: 'Pacific/Chuuk' },
        {
            offset: '+10:30',
            label: '(GMT+10:30) Central Time - Adelaide',
            tzCode: 'Australia/Adelaide',
        },
        {
            offset: '+11:00',
            label: '(GMT+11:00) Casey',
            tzCode: 'Antarctica/Casey',
        },
        {
            offset: '+11:00',
            label: '(GMT+11:00) Eastern Time - Hobart',
            tzCode: 'Australia/Hobart',
        },
        {
            offset: '+11:00',
            label: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
            tzCode: 'Australia/Sydney',
        },
        { offset: '+11:00', label: '(GMT+11:00) Efate', tzCode: 'Pacific/Efate' },
        {
            offset: '+11:00',
            label: '(GMT+11:00) Guadalcanal',
            tzCode: 'Pacific/Guadalcanal',
        },
        { offset: '+11:00', label: '(GMT+11:00) Kosrae', tzCode: 'Pacific/Kosrae' },
        {
            offset: '+11:00',
            label: '(GMT+11:00) Moscow+08 - Magadan',
            tzCode: 'Asia/Magadan',
        },
        {
            offset: '+11:00',
            label: '(GMT+11:00) Norfolk',
            tzCode: 'Pacific/Norfolk',
        },
        { offset: '+11:00', label: '(GMT+11:00) Noumea', tzCode: 'Pacific/Noumea' },
        {
            offset: '+11:00',
            label: '(GMT+11:00) Ponape',
            tzCode: 'Pacific/Pohnpei',
        },
        {
            offset: '+12:00',
            label: '(GMT+12:00) Funafuti',
            tzCode: 'Pacific/Funafuti',
        },
        {
            offset: '+12:00',
            label: '(GMT+12:00) Kwajalein',
            tzCode: 'Pacific/Kwajalein',
        },
        { offset: '+12:00', label: '(GMT+12:00) Majuro', tzCode: 'Pacific/Majuro' },
        {
            offset: '+12:00',
            label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
            tzCode: 'Asia/Kamchatka',
        },
        { offset: '+12:00', label: '(GMT+12:00) Nauru', tzCode: 'Pacific/Nauru' },
        { offset: '+12:00', label: '(GMT+12:00) Tarawa', tzCode: 'Pacific/Tarawa' },
        { offset: '+12:00', label: '(GMT+12:00) Wake', tzCode: 'Pacific/Wake' },
        { offset: '+12:00', label: '(GMT+12:00) Wallis', tzCode: 'Pacific/Wallis' },
        {
            offset: '+13:00',
            label: '(GMT+13:00) Auckland',
            tzCode: 'Pacific/Auckland',
        },
        {
            offset: '+13:00',
            label: '(GMT+13:00) Enderbury',
            tzCode: 'Pacific/Enderbury',
        },
        {
            offset: '+13:00',
            label: '(GMT+13:00) Fakaofo',
            tzCode: 'Pacific/Fakaofo',
        },
        { offset: '+13:00', label: '(GMT+13:00) Fiji', tzCode: 'Pacific/Fiji' },
        {
            offset: '+13:00',
            label: '(GMT+13:00) Tongatapu',
            tzCode: 'Pacific/Tongatapu',
        },
        { offset: '+14:00', label: '(GMT+14:00) Apia', tzCode: 'Pacific/Apia' },
        {
            offset: '+14:00',
            label: '(GMT+14:00) Kiritimati',
            tzCode: 'Pacific/Kiritimati',
        },
    ],

    TZ_LABELS: [
        '-11:00',
        '-10:00',
        '-09:00',
        '-08:00',
        '-07:00',
        '-06:00',
        '-05:00',
        '-04:00',
        '-03:00',
        '-02:00',
        '-01:00',
        '+00:00',
        '+01:00',
        '+02:00',
        '+03:00',
        '+04:00',
        '+05:00',
        '+06:00',
        '+07:00',
        '+08:00',
        '+09:00',
        '+10:00',
        '+11:00',
        '+12:00',
    ],
};

export default CONSTANTS
